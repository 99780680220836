import { APIService, API_CONSUMER } from '..';
const BaseURL = `${API_CONSUMER}/api/v1/customer`;

export default {
  getCustomers: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/getCustomers`, method: 'POST' }),

  addUpdateCustomer: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/addUpdateCustomer`, method: 'POST' }),

  changeCustomerActivation: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/changeCustomerActivation`, method: 'POST' }),
};
