// THEME VALUES
import  colors from "./theme/base/colors";
import  breakpoints from "./theme/base/breakpoints";
import  typography from "./theme/base/typography";
import  boxShadows from "./theme/base/boxShadows";
import  borders from "./theme/base/borders";
import  globals from "./theme/base/globals";

export const THEME = {
  colors,
  breakpoints,
  typography,
  boxShadows,
  borders,
  globals,
};

// export const Assets = {
//   logos: {
//     png: {
//       logoNoBackground: 
//     }
//   }
// };
