import { createSlice } from '@reduxjs/toolkit';

// api
import { CustomerV1API } from '../../api';

// utils
import { startLoading, hasError, handleResponse, dataListState, dataState } from '../constant';
import {isEmpty} from '../../utils';

// ----------------------------------------------------------------------

const initialState = {
  // common
  isLoading: false,
  error: false,

  // customers
  customers: dataListState,
  customerData: dataState,
};

const slice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    startLoading,
    hasError,
    handleResponse,
    // STATE CLEAN UP 
    customerStateCleaner(state, action) {
      const field = action.payload;

      if(typeof field === "string"){
        state[field] = initialState[field];
      }
    },
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { customerStateCleaner } = slice.actions;

// -----------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------

export const getCustomers = ({   
  customer_id=null, 
  is_active=null,
  sortBy='latest', 
  pageNo=0, 
  searchQuery="",  
  basic=false,
  ...other
}) => async (dispatch) => {
  
  const field = isEmpty(customer_id) ? "customers" : "customerData";
  const payload = { customer_id, is_active, sortBy, pageNo, searchQuery, basic, ...other };
  
  try {
    dispatch(slice.actions.startLoading(field));
    const response = await CustomerV1API.getCustomers({ ...payload });    
    dispatch(slice.actions.handleResponse({ 
      field, 
      data: { data: isEmpty(customer_id) ? response?.data?.customers :  response?.data?.customers[0], counts: response?.data?.counts }, 
      response
    }));
    return response;
  } catch (error) {
    dispatch(slice.actions.hasError({ field, error }));
  }
};

export const addUpdateCustomer = ({ ...payload }) => async (dispatch) => {
  try {
    dispatch(slice.actions.startLoading());
    const response = await CustomerV1API.addUpdateCustomer({ ...payload });
    return response;
  } catch (error) {
    dispatch(slice.actions.hasError({ error }))
  }
};

export const changeCustomerActivation = ({ ...payload }) => async (dispatch) => {
  try {
    dispatch(slice.actions.startLoading());
    const response = await CustomerV1API.changeCustomerActivation({ ...payload });
    return response;
  } catch (error) {
    dispatch(slice.actions.hasError({ error }))
  }
};
   