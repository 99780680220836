import { APIService, API_CONSUMER } from '../';
const BaseURL = `${API_CONSUMER}/api/v1/invoice`;

export default {
  getFromContacts: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/getFromContacts`, method: 'POST' }),
  
  addUpdateFromContact: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/addUpdateFromContact`, method: 'POST' }),
  
  changeFromContactActivation: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/changeFromContactActivation`, method: 'POST' }),

  updateFromContactLogo: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/updateFromContactLogo`, method: 'POST' }),

  saveInvoice: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/saveInvoice`, method: 'POST' }),

  getInvoices: async ({ ...payload }) =>  await APIService({ payload, url: `${BaseURL}/getInvoices`, method: 'POST' }),
  
  getNextInvoiceNumber: async ({ ...payload }) =>  await APIService({ payload, url: `${BaseURL}/getNextInvoiceNumber`, method: 'GET' }),

  changeInvoiceStatus: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/changeInvoiceStatus`, method: 'POST' }),

  archivedOrRestoreInvoice: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/archivedOrRestoreInvoice`, method: 'POST' }),
  
  copyInoice: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/copyInoice`, method: 'POST' }),
  
  removePayment: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/removePayment`, method: 'POST' }),
  // getMailedInvoices: async ({ pageNo=0, ...other }) => {
  //   const payload = { pageNo, ...other };
  //   const response = await APIService({ payload, url: `${BaseURL}/getMailedInvoices`, method: 'POST' });
  //   return response
  // },

  // getShiftsForInvoicing: async ({ ...payload }) => {
  //   const response = await APIService({ payload, url: `${BaseURL}/getShiftsForInvoicing`, method: 'POST' });
  //   return response;
  // },

  // getShiftsToCreateInvoice: async ({ ...payload }) => {
  //   const response = await APIService({ payload, url: `${BaseURL}/getShiftsToCreateInvoice`, method: 'POST' });
  //   return response;
  // },

  // insertInvoicePayDetails: async ({ ...payload }) => {
  //   const response = await APIService({ payload, url: `${BaseURL}/insertInvoicePayDetails`, method: 'POST' });
  //   return response;
  // },

  // getInvoicePayDetails: async ({ ...payload }) => {
  //   const response = await APIService({ payload, url: `${BaseURL}/getInvoicePayDetails`, method: 'POST' });
  //   return response;
  // },

  // getTotalInvoiceAndAmount: async ({ ...payload }) => {
  //   const response = await APIService({ payload, url: `${BaseURL}/getTotalInvoiceAndAmount`, method: 'POST' });
  //   return response;
  // },

  // sendInvoiceMailToClient: async ({ ...payload }) => {
  //   const response = await APIService({ payload, url: `${BaseURL}/sendInvoiceMailToClient`, method: 'POST' });
  //   return response;
  // },
};
