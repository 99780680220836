import { Container } from "@mui/material";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import Icon from "@mui/material/Icon";

import { MKBox, MKTypography } from "../../components";

import { THEME } from "../../assets";

function MainFooter() {
  return (
    // inherit","primary","secondary","info","success","warning","error","light","dark","text","white"
    <MKBox width="100%" mt="auto" bgColor="#141414" flexDirection={"column"} alignItems="center" px={8} py={3}>
        <MKBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          color={"#bebebe"}
          fontSize={THEME.typography.size.sm}
          paddingBottom={"16px"}
          borderBottom="1px solid #b1b1b1"
        >
          Create, Manage Invoices online with &nbsp;
          <Link href={"/"}>
            <MKTypography variant="button" fontWeight="medium" color={"white"}>
              A1abilities Free Invoice Generator
            </MKTypography>
          </Link>          
        </MKBox>

        <MKBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          color={"#bebebe"}
          fontSize={THEME.typography.size.sm}
          paddingTop={"16px"}
        >
          <MKTypography variant="button" fontWeight="regular" color={"light"}>
            Copyright 2019 ©All rights reserved | &nbsp; 
            <a href={"https://a1abilities.com"} target="_blank">
              <MKTypography variant="button" fontWeight="medium" color={"white"}>
                A1abilities PTY TLD
              </MKTypography>
            </a>
            &nbsp; ABN# 82631548224
          </MKTypography>
          <MKTypography variant="button" fontWeight="regular" color={"light"}>
            Powered by &nbsp;
            <a href={"https://a1abilities.com"} target="_blank">
              <MKTypography variant="button" fontWeight="medium" color={"white"}>
                A1abilities
              </MKTypography>
            </a>          
          </MKTypography>
        </MKBox>
    </MKBox>
  );
};

export default MainFooter;
