import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, title="", subtitle="", ...other }, ref) => {
  return (
    <Box ref={ref} {...other}>
      <Helmet>
        <title>{`${title || ""} ${subtitle || ' | A1invoice'}`}</title>
      </Helmet>
      {children}
    </Box>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default Page;