import { Fragment, useRef, useState } from 'react';

import { Link as RouterLink } from 'react-router-dom';
// material
import { alpha } from '@mui/material/styles';
import { Button, IconButton, Tooltip, Box, Divider, MenuItem, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';

// routes
import { PATH_PAGE } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
import useIsMounted from '../../hooks/useIsMounted';
import useConstant from '../../hooks/useConstant';
import { useSelector } from '../../redux/store';

// components
import { MKBox, MKButton, MKTypography, MenuPopover, MyAvatar } from '../../components';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Profile',
    icon: <PersonIcon />,
    linkTo: PATH_PAGE.settings.profile
  },
  {
    label: 'Settings',
    icon: <SettingsIcon />,
    linkTo: PATH_PAGE.settings.root
  }
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const isMounted = useIsMounted();
  const { user, logout } = useAuth();
  
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await logout();
      if (isMounted()) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Fragment>
      <Tooltip title="Account">
        <IconButton
          ref={anchorRef}
          onClick={handleOpen}
          sx={{
            padding: 0,
            width: 44,
            height: 44,
            ...(open && {
              '&:before': {
                zIndex: 1,
                content: "''",
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                position: 'absolute',
                // bgcolor: (theme) => alpha(theme.palette.grey[600], 0.72)
              }
            })
          }}
        >
          <MyAvatar sx={{backgroundColor: "#344767"}} />
        </IconButton>
      </Tooltip>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{
          minWidth: 220,
          border: '1px solid #e0e0e0',
        }}
      >
        <MKBox sx={{ my: 1.5, px: 2.5 }}>
          <MKTypography variant="h6">
            {`${user?.name || "User"}`}
          </MKTypography>
          <MKTypography variant="body2">
            {user?.email}
          </MKTypography>
        </MKBox>

        <Divider sx={{ my: 1, border: "1px solid #00000033" }} />

        {MENU_OPTIONS.map((option) => {
          const include = true;
          return (
            include && (
              <MenuItem
                key={option.label}
                to={option.linkTo}
                component={RouterLink}
                onClick={handleClose}
                sx={{ typography: 'subtitle2', py: 1, px: 2.5, fontWeight: 400 }}
              >
                <MKBox sx={{ mr: 2, display: 'flex' }}>
                  {option.icon}
                </MKBox>
                {option.label}
              </MenuItem>
            )
          );
        })}

        <MKBox sx={{ p: 2, pt: 1.5 }}>
          <MKButton fullWidth color="error" variant="contained" onClick={handleLogout}>
            Logout
          </MKButton>
        </MKBox>
      </MenuPopover>
    </Fragment>
  );
}
