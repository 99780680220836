import colors from "../../base/colors";
import borders from "../../base/borders";
import boxShadows from "../../base/boxShadows";
import pxToRem from "../../functions/pxToRem";

const { grey, white } = colors;
const { borderRadius } = borders;
const { tabsBoxShadow } = boxShadows;

export default {
  styleOverrides: {
    root: {
      position: "relative",
      backgroundColor: colors.grey[200],
      // borderRadius: borderRadius.md,
      // minHeight: "unset",
      paddingTop: pxToRem(4),
      paddingRight: pxToRem(4),
      paddingLeft: pxToRem(4),
      border: "1px solid #e1e1e1",
    },

    flexContainer: {
      height: "100%",
      position: "relative",
      zIndex: 10,
    },

    fixed: {
      overflow: "unset !important",
      overflowX: "unset !important",
    },

    vertical: {
      "& .MuiTabs-indicator": {
        width: "100%",
      },
    },

    // indicator: {
    //   height: "100%",
    //   borderRadius: borderRadius.sm,
    //   backgroundColor: white.main,
    //   borderBottom: "2px solid #e91e63",
    //   boxShadow: tabsBoxShadow.indicator,
    //   transition: "all 500ms ease",
    // },
  },
};
