import { Navigate } from 'react-router-dom';

import MainLayout from '../layouts/main';
import AuthGuard from '../guards/AuthGuard';
import { 
  CustomerAddNew, CustomerEdit, CustomerList, 
  InvoiceCreate, InvoiceEdit, InvoiceList, InvoiceView, 
  FromContactList, FromContactAddNew, FromContactEdit,
  BankList, BankAddNew, BankEdit,
  Settings 
} from './helpers';
import { PATH_PAGE } from './paths';

// -----------------------------------------------------------------------
// -----------------------------------------------------------------------

export default function MasterUser() {
  return [
    { 
      path: "*", 
      element: ( <AuthGuard> <MainLayout /> </AuthGuard> ),
      children: [
        {
          path: 'invoice',
          children: [
            { index: true, element: <Navigate to="/invoice/list" replace /> },
            { 
              path: 'list', element: 
              <InvoiceList 
                breadcrumb={{
                  linkButton: { label: "New Invoice", href: PATH_PAGE.invoice.create } 
                }}
              /> 
            },
            { path: 'create', element: <InvoiceCreate /> },
            { path: 'edit', element: <InvoiceEdit /> },
            { path: 'view', element: <InvoiceView /> },
            InvoiceView
          ]
        },
        {
          path: 'customer',
          children: [
            { index: true, element: <Navigate to="/customer/list" replace /> },
            { 
              path: 'list', element: 
              <CustomerList 
                breadcrumb={{
                  linkButton: { label: "Add Customer", href: PATH_PAGE.customer.addNew } 
                }}
              /> 
            },
            { path: 'add-new', element: <CustomerAddNew /> },
            { path: 'edit', element: <CustomerEdit /> },
          ]
        },
        {
          path: 'from-contact',
          children: [
            { index: true, element: <Navigate to="/from-contact/list" replace /> },
            { 
              path: 'list', element: 
              <FromContactList 
                breadcrumb={{
                  linkButton: { label: "Add From Contact", href: PATH_PAGE.fromContact.addNew } 
                }}
              /> 
            },
            { path: 'add-new', element: <FromContactAddNew /> },
            { path: 'edit', element: <FromContactEdit /> },
          ]
        },
        {
          path: 'bank',
          children: [
            { index: true, element: <Navigate to="/bank/list" replace /> },
            { 
              path: 'list', element: 
              <BankList 
                breadcrumb={{
                  linkButton: { label: "Add Bank Account", href: PATH_PAGE.bank.addNew } 
                }}
              /> 
            },
            { path: 'add-new', element: <BankAddNew /> },
            { path: 'edit', element: <BankEdit /> },
          ]
        },
        { path: 'settings', element: <Settings /> },
      ]    
    },    
  ];
};
