import { Navigate } from 'react-router-dom';
import MainLayout from '../layouts/main';

import { PATH_PAGE } from './paths';

// components
import { InvoiceCreate } from './helpers';

export default function PublicRoutes() {
  return [    
    {
      path: 'invoice',
      element: <MainLayout />,
      children: [
        { index: true, element: <Navigate to={PATH_PAGE.invoice.create} replace /> },  
        { path: 'create', element: <InvoiceCreate /> }
      ]
    },
  ];
};
