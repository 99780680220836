export const styles = {
  normal: {
    table: {

    },
    tableHeadStyle: {
      color: "rgb(123, 128, 154)",
      fontSize: "0.85rem",
      borderBottom: "0.0625rem solid rgb(222, 226, 230)",
    },    
    tableBodyRow: {

    },
    groupedRowLabel: {

    }
  },
  margin: {
    table: {
      borderCollapse: 'separate',
      borderSpacing: (theme) => `0 ${theme.spacing(3)}`,
      minWidth: 600,
      marginTop: (theme) => `-${theme.spacing(3)}`,
      p: '1px'
    },
    tableBodyRow: {
      boxShadow: 1,
      transition: (theme) => theme.transitions.create('box-shadow', {
        easing: theme.transitions.easing.easeOut
      }),
      '&:hover': {
        boxShadow: 8
      },
      '& > td': {
        backgroundColor: 'background.paper',
        borderBottom: 0
      }
    },
    groupedRowLabel: {
      px: 0
    }
  }
}