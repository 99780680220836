import { Fragment } from "react";
import { Outlet } from 'react-router-dom';

// @mui material components
import { MKBox } from "../../components";

import MainNavbar from "./MainNavbar";
import MainFooter from "./MainFooter";

function MainLayout() {
  return (
    <Fragment>
      <MainNavbar />
      <MKBox paddingTop={"96px"} display="flex" flexDirection="column" bgColor="white" minHeight="100vh">  
        <Outlet />        
      </MKBox>
      <MainFooter />
    </Fragment>
  );
};

export default MainLayout;
