export const defaultState = { isLoading: false, error: false };
export const dataState = { ...defaultState, data: null };
export const dataListState = { ...defaultState, data: [], counts: 0 };

export function startLoading(state, action) {
  const field = action.payload;
  if(field){
    state[field].error = false;
    state[field].isLoading = true;
  } else {
    state.isLoading = true;
  }
};

export function stopLoading(state, action) {
  const field = action.payload;
  if(field){
    state[field].error = false;
    state[field].isLoading = false;
  } else {
    state.isLoading = false;
  }
};

export function hasError(state, action) {
  const { field, error } = action.payload;
  if(field) { 
    state[field].isLoading = false;
    state[field].error = error;
  } else {
    state.isLoading = false;
    state.error = error;
  }
};

export function handleResponse(state, action) {
  const { field, data, response } = action.payload;
  const { message, isSuccess } = response;
  if(isSuccess){
    state[field] = { ...state[field], ...data };
    state[field].isLoading = false;        
    state[field].error = false;        
  } else {
    state[field].error = message;
    state[field].isLoading = false;
  }      
};