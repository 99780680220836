import { Navigate } from 'react-router-dom';

import MainLayout from '../layouts/main';
import AuthGuard from '../guards/AuthGuard';

// -----------------------------------------------------------------------
// -----------------------------------------------------------------------

export default function BasicUser() {
  return [
    {
      path: 'dashboard',
      element: ( <AuthGuard> <MainLayout /> </AuthGuard> ),
      children: [
        { index: true, element: <Navigate to="/dashboard/scheduleBoard" replace /> },
        // {
        //   path: 'client',
        //   children: [
        //     { path: 'list', element: 
        //       <ClientList 
        //         breadcrumb={{
        //           linkButton: { label: "Add Client", href: PATH_PAGE.management.client.new } 
        //         }}
        //       /> 
        //     },
        //     { path: 'new', element: <ClientCreate /> },
        //     { path: 'edit', element: <ClientEdit /> },
        //     { path: 'profile', element: <ClientProfile /> },
        //   ]
        // },
        // { path: '*', element: <NotFound /> }
      ]
    }
  ];
};
