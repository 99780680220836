import React, { useEffect } from 'react';

export default function ErrorFocusOnSubmit({ submitCount, errors, }) {
  useEffect(() => {
    if(errors) {
      const eleName = Object.keys(errors)[0];      
      if(eleName && typeof eleName === "string"){
        const element = document.getElementsByName(eleName);
        const focusElement = element[0];
        const offset = 200;
        const speed = 700;
        if(element && focusElement){
          // new Promise((resolve, reject) => {
          //   console.log('enter promise')
          //   focusElement.scrollIntoView({
          //     behavior: 'smooth'
          //   });
          //   console.log('after scrollIntoView')
          //   resolve();
          //   console.log('after resolve')
          // }).then(() => {
          //   console.log('enter inthen')
          //   window.scrollTo(0,window.scrollY - 100);
          //   console.log('after scrollTo')
          // })

          focusElement.focus();

          // document.body.animate({
          //   offset: focusElement.offsetTop + offset
          // }, speed);          
          // focusElement.focus();          
          
          
          //   focusElement.scrollIntoView({
          //     behavior: 'smooth'
          //   });
          //   window.scrollTo(0,window.scrollY - 100);

          //   focusElement.scrollIntoView({
          //     behavior: 'smooth'
          //   });
          // setTimeout(() => {focusElement.focus();}, 700);

          // var x = window.scrollX, y = window.scrollY;
          // element[0].focus();
          // window.scrollTo(x, y);
        }
      }
    }
  },[submitCount]);
  return null;
}