import { createSlice } from '@reduxjs/toolkit';

// api
import {StaticV1API} from '../../api';
import { startLoading, hasError, handleResponse, dataListState } from '../constant';

// ----------------------------------------------------------------------

const initialState = {
  // common
  isLoading: false,
  error: false,

  currencies: dataListState,
  dateFormat: dataListState,
  invoiceItemType: dataListState,
  invoiceStatus: dataListState,

  gender: dataListState,
  language: dataListState,
  userRole: dataListState,
  religion: dataListState,
  nameTitle: dataListState,
  familyStatus: dataListState,
  countries: dataListState,
  states: dataListState,
  suburbs: dataListState,
};

const slice = createSlice({
  name: 'static',
  initialState,
   reducers: {
    startLoading,
    hasError,
    handleResponse,

    // STATE CLEAN UP 
    staticCleanUpFunction(state, action) {
      const field = action.payload;
      if(typeof field === "string"){
        state[field] = initialState[field];
      }
    },
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;
export const { staticCleanUpFunction } = slice.actions;

// ----------------------------------------------------------------------
// ----------------------------------------------------------------------
export const getStaticDataFn = ({ fieldType, valueAs = 'value', is_active = 1, ...other }) => async (dispatch) => {
  const field = fieldType;
  dispatch(slice.actions.startLoading(field));
  try {
    const response = await StaticV1API.getStaticRecordList({ fieldType, valueAs, is_active, ...other });
    const data = { data: response?.data?.recordList, counts: response?.data?.counts };
    dispatch(slice.actions.handleResponse({ field, data, response }));
    return response;
  } catch (error) {
    dispatch(slice.actions.hasError({ field, error }));
  }
};

export const insertNewStaticRecord = ({ fieldType="", value = '', callback, ...other }) => async (dispatch) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await StaticV1API.insertNewStaticRecord({ fieldType, value, ...other });
    if(callback && typeof callback === 'function'){
      dispatch(callback({}));
    };    
    return response;
  } catch (error) {
    dispatch(slice.actions.hasError({ error }));
  }
};

// ----------------------------------------------------------------------
// ----------------------------------------------------------------------

export const getLanguageList = ({ is_active = 1, ...other }) => async (dispatch) => {
  const field = 'language';
  dispatch(slice.actions.startLoading(field));
  try {
    const response = await StaticV1API.getLanguageList({ is_active, ...other });
    const data = { data: response?.data?.languageList, counts: response?.data?.languageList?.length };
    dispatch(slice.actions.handleResponse({ field, data, response }));
    return;
  } catch (error) {
    dispatch(slice.actions.hasError({ field, error }));
  }
};

export const getCurrencyList = ({ is_active = 1, currency_id, ...other }) => async (dispatch) => {
  const field = 'currencies';
  dispatch(slice.actions.startLoading(field));
  try {
    const response = await StaticV1API.getCurrencyList({ is_active, currency_id, ...other });
    const data = { data: response?.data?.currencies, counts: response?.data?.currencies?.length };
    dispatch(slice.actions.handleResponse({ field, data, response }));
    return;
  } catch (error) {
    dispatch(slice.actions.hasError({ field, error }));
  }
};

export const getCountryListOrData = ({ is_active = 1, country_id, ...other }) => async (dispatch) => {
  const field = 'countries';
  dispatch(slice.actions.startLoading(field));
  try {
    const response = await StaticV1API.getCountryListOrData({ is_active, country_id, ...other });
    const data = { data: response?.data?.countries, counts: response?.data?.countries?.length };
    dispatch(slice.actions.handleResponse({ field, data, response }));
    return;
  } catch (error) {
    dispatch(slice.actions.hasError({ field, error }));
  }
};

export const getStateListOrData = ({ is_active = 1, state_id, country_id, ...other }) => async (dispatch) => {
  const field = 'states';
  dispatch(slice.actions.startLoading(field));
  try {
    const response = await StaticV1API.getStateListOrData({ is_active, state_id, country_id, ...other });
    const data = { data: response?.data?.states, counts: response?.data?.states?.length };
    dispatch(slice.actions.handleResponse({ field, data, response }));
    return;
  } catch (error) {
    dispatch(slice.actions.hasError({ field, error }));
  }
};

export const getSuburbListOrData = ({ is_active = 1, suburb_id, state_id, country_id, ...other }) => async (dispatch) => {
  const field = 'suburbs';
  dispatch(slice.actions.startLoading(field));
  try {
    const response = await StaticV1API.getSuburbListOrData({ is_active, suburb_id, state_id, country_id, ...other });
    const data = { data: response?.data?.suburbs, counts: response?.data?.suburbs?.length };
    dispatch(slice.actions.handleResponse({ field, data, response }));
    return;
  } catch (error) {
    dispatch(slice.actions.hasError({ field, error }));
  }
};


export const getInvoiceItemType = ({ fieldType = 'invoiceItemType', valueAs = 'item', is_active = 1, ...other }) => async (dispatch) => {
  return await dispatch(getStaticDataFn({ fieldType, valueAs, is_active, ...other }));
};

export const getDateFormats = ({ fieldType = 'dateFormat', valueAs = 'format', is_active = 1, ...other }) => async (dispatch) => {
  return await dispatch(getStaticDataFn({ fieldType, valueAs, is_active, ...other }));
};

export const getGenderList = ({ fieldType = 'gender', valueAs = 'gender', is_active = 1, ...other }) => async (dispatch) => {
  return await dispatch(getStaticDataFn({ fieldType, valueAs, is_active, ...other }));
};

export const getRoleList = ({ fieldType = 'userRole', valueAs = 'role_name', is_active = 1, ...other }) => async (dispatch) => {
  return await dispatch(getStaticDataFn({ fieldType, valueAs, is_active, ...other }));
};

export const getReligionList = ({ fieldType = 'religion', valueAs = 'religion', is_active = 1, ...other }) => async (dispatch) => {
  return await dispatch(getStaticDataFn({ fieldType, valueAs, is_active, ...other }));
};

export const getNameTitleList = ({ fieldType = 'nameTitle', valueAs = 'title', is_active = 1, ...other }) => async (dispatch) => {
  return await dispatch(getStaticDataFn({ fieldType, valueAs, is_active, ...other }));  
};

export const getFamilyStatusList = ({ fieldType = 'familyStatus', valueAs = 'family_status', is_active = 1, ...other }) => async (dispatch) => {
  return await dispatch(getStaticDataFn({ fieldType, valueAs, is_active, ...other }));  
};

export const getInvoiceStatusList = ({fieldType = 'invoiceStatus', valueAs = 'invoice_status', is_active = 1, ...other}) => async (dispatch) => {
  return await dispatch(getStaticDataFn({ fieldType, valueAs, is_active, ...other }));
};

export const insertNewReferences = ({ fieldType = 'references', value = '', ...other }) => async (dispatch) => {
  return await dispatch(insertNewStaticRecord({ fieldType, value, callback: getReferences, ...other }));
};
