import { Avatar } from '@mui/material';

// hooks
import useAuth from '../hooks/useAuth';
//
import { createAvatar, isEmpty } from '../utils';
import { API_URL } from '../api';
// ----------------------------------------------------------------------

export default function MyAvatar({ profile = null, ...other }) {
  const { user } = useAuth();
  const avatarProps = {
    src: (isEmpty(profile?.src) ? `${API_URL}/api/v1/downloadFile?path=profilePictures/${user?.profile_picture}` : profile?.src),
    name: (isEmpty(profile?.name) ? `${user?.name || "User"}` : profile?.name)
  };

  return (
    <Avatar
      src={!isEmpty(user?.profile_picture) ? avatarProps?.src : null}
      alt={avatarProps.name}
      color={avatarProps.src ? 'default' : createAvatar(avatarProps.name).color}
      {...other}
    >
      {createAvatar(avatarProps.name).name}
    </Avatar>
  );
}
