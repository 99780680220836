import { combineReducers } from 'redux';
// import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// slices
import userReducer from './slices/user';
import customerReducer from './slices/customer';
import authReducer from './slices/auth';
import staticReducer from './slices/static';
import settingReducer from './slices/setting';
import layoutReducer from './slices/layout';
import invoiceReducer from './slices/invoice';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

// const productPersistConfig = {
//   key: 'product',
//   storage,
//   keyPrefix: 'redux-',
//   whitelist: ['sortBy', 'checkout'],
// };

const rootReducer = combineReducers({
  customer: customerReducer,
  user: userReducer,
  invoice: invoiceReducer,
  auth: authReducer,
  static: staticReducer,
  setting: settingReducer,
  layout: layoutReducer,
  // product: persistReducer(productPersistConfig, productReducer),
});

export { rootPersistConfig, rootReducer };
