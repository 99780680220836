import { format, getTime as getTimeFns, formatDistanceToNow } from 'date-fns';
import moment from 'moment';

const isEmpty = value => value === undefined
	|| value === null
	|| (typeof value === 'object' && Object.keys(value).length === 0)
	|| (typeof value === 'string' && value.trim().length === 0);

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
};

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
};

export function fTimestamp(date) {
  return getTimeFns(new Date(date));
};

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
};

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
};

function getValid(output) {
  return output === "Invalid date" ? "" : output;
};

export function getDate(date) {
  return getValid(moment(date).format('YYYY-MM-DD'));
};

export function getDateInDDDYYYYMMDD(date) {
  return getValid(moment(date).format('ddd, MMMM DD, YYYY'));
};

export function addInDate(date, ammount = 1, unit = 'days') {
  return getValid(moment(date).add(ammount, unit).format('YYYY-MM-DD'));
};

export function addInDateTime(date, ammount = 1, unit = 'days') {
  return getValid(moment(date).add(ammount, unit).format('YYYY-MM-DD HH:mm:ss'));
};

export function subtractInDate(date, ammount = 1, unit = 'days') {
  return getValid(moment(date).subtract(ammount, unit).format('YYYY-MM-DD'));
};

export function getCurrentDayName(date) {
  return getValid(moment(date).format('dddd'));
};

export function getDateInDDMMYYYY(date) {
  return getValid(moment(date).format('DD-MM-YYYY'));
};

export function getTimefullformat(time) {
  if(time?.length === 5){
    return getValid(moment(`2022-01-15T${time}:00`).format('HH:mm:ss'));
  }
  if(time?.length === 8){
    return getValid(moment(`2022-01-15T${time}`).format('HH:mm:ss'));
  }
  return getValid(moment(time).format('HH:mm:ss'));
};

export function getDateInDDMMM(date) {
  return getValid(moment(date).format('DD MMM'));
};

export function getCurrentDateDDMMYYYY() {
  return getValid(moment().format('DD-MM-YYYY'));
};

export function getTimeinDBFormat(date) {
  return getValid(moment(date).format('HH:mm:ss'));
};

export function getTime(date) {
  if(date?.length === 5){
    return getValid(moment(`2022-01-15T${date}:00`).format('HH:mm'));
  }
  if(date?.length === 8){
    return getValid(moment(`2022-01-15T${date}`).format('HH:mm'));
  }

  return getValid(moment(date).format('HH:mm'));
};

export function getDateTime(date) {
  return getValid(moment(date).format('YYYY-MM-DD HH:mm:ss'));
};

export function getYearsFromDate(date) {
  const d = moment(date).format('YYYY-MM-DD');
  return moment().diff(d, 'years');
};

export function Time24To12(time) {
  return getValid(moment(time, ["HH:mm"]).format("hh:mm A"));
};

export function secondsToHms(sec) {
  sec = Number(sec);
  const h = Math.floor(sec / 3600);
  const m = Math.floor((sec % 3600) / 60);
  const s = Math.floor((sec % 3600) % 60);

  const hDisplay = h > 0 ? h : '00';
  const mDisplay = m > 0 ? m : '00';
  const sDisplay = s > 0 ? s : '00';
  return `${hDisplay}:${mDisplay}:${sDisplay}`;
};

export function TimeDiff(start, end) {
  if (!isEmpty(start) && !isEmpty(end)) {
    const startDate = getDateTime(start);
    const endDate = getDateTime(end);

    let diff = moment(endDate).valueOf() - moment(startDate).valueOf();
    let hours = Math.floor(diff / 1000 / 60 / 60);
    diff -= hours * 1000 * 60 * 60;
    const minutes = Math.floor(diff / 1000 / 60);

    // If using time pickers with 24 hours format, add the below line get exact hours
    if (hours < 0) hours += 24;
    // console.log(`${(hours <= 9 ? '0' : '') + hours}:${minutes <= 9 ? '0' : ''}${minutes}`);
    return `${(hours <= 9 ? '0' : '') + hours}:${minutes <= 9 ? '0' : ''}${minutes}`;
  }
  return `00:00`;
};

export function isBeetweenOrSame(compare, date1, date2) {
  const c = getDate(compare);
  const d1 = getDate(date1);
  const d2 = getDate(date2);

  return moment(c).isBetween(d1, d2) || moment(c).isSame(d1) || moment(c).isSame(d2);
};
 