/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
import { useState, useEffect } from 'react';
import useAuth from '../../../hooks/useAuth';
import useConstant from '../../../hooks/useConstant';

import SuperAdmin from './SuperAdmin';
import MasterUser from './MasterUser';
import AccountUser from './AccountUser';
import Public from './Public';


export default function MenuConfig() {
  const { isAuthenticated, user } = useAuth();
  const { userRole } = useConstant();
  const [menu, setMenu] = useState([]);
  const [action, setAction] = useState(null);
  
  useEffect(() => {
    let Routes = [];
    let Action = null;
    if (isAuthenticated) {
      Routes = [...(
        userRole?.isSuperAdmin ? [...SuperAdmin?.menu] : 
        userRole?.isMasterUser ? [...MasterUser?.menu] : 
        userRole?.isAccountUser ? [...AccountUser?.menu] : []
      )];
      Action = 
        userRole?.isSuperAdmin ? SuperAdmin?.action :
        userRole?.isMasterUser ? MasterUser?.action : 
        userRole?.isAccountUser ? AccountUser?.action : null;
    } else {
      Routes = Public?.menu || [];
      Action = Public?.action || null;
    }
    setMenu([ ...Routes ]);
    setAction(Action);
  }, [isAuthenticated, user]);
  
  return { menu, action };
}
