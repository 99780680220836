import { memo } from 'react';
import PropTypes from 'prop-types';

// material
import { TableRow, IconButton, Typography, Checkbox, TableCell } from '@mui/material';
import { styles } from './styles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { isEmpty } from './utils';
// -------------------------------------------------

const EditableRow = memo(({ colSpan, EditContent }) => (
  <TableRow>
    <TableCell
      colSpan={ colSpan + 1 }
      sx={{
        p: 0,
        position: 'relative',
        '&:after': {
          position: 'absolute',
          content: '" "',
          top: 0,
          left: 0,
          backgroundColor: 'primary.main',
          width: 3,
          height: 'calc(100% + 1px)'
        }
      }}
    >
      { EditContent }
    </TableCell>
  </TableRow>
));

const NormalRow = memo(({ variant, editItem, editableContent, handleChangeEditItem, isItemSelected, enableSelect, handleCheckboxClick, RowItem, item, index, colSpan, selected }) => {
  const openEdit = editItem?.id === item?.id;
  const isEditable = !isEmpty(editableContent);
  const EditContent = isEditable ? editableContent?.EditContent : null;

  return (
    <>
      <TableRow
        hover
        key={index.toString()}
        tabIndex={-1}
        sx={styles[variant]?.tableBodyRow}
        role="checkbox"
        aria-checked={isItemSelected}
        selected={isItemSelected}
      >
        {isEditable && (
          <TableCell
            padding="checkbox"
            sx={{
              ...(openEdit && {
                position: 'relative',
                '&:after': {
                  position: 'absolute',
                  content: '" "',
                  top: 0,
                  left: 0,
                  backgroundColor: 'primary.main',
                  width: 3,
                  height: 'calc(100% + 1px)'
                }
              })
            }}
            width="25%"
          >
            <IconButton onClick={() => handleChangeEditItem(openEdit ? null : item)}>
              {openEdit
                ? <ArrowBackIosNewIcon fontSize="small" style={{ transform: 'rotate(270deg)'}}/>
                : <ArrowForwardIosIcon fontSize="small" />
              }
            </IconButton>
          </TableCell>
        )}
        {enableSelect && (
          <TableCell padding="checkbox">
            <Checkbox 
              checked={isItemSelected}
              onClick={(e)=> handleCheckboxClick(e, item?.id)}
            />
          </TableCell>
        )}
        <RowItem item={item} index={index} />   
      </TableRow>
      {openEdit && (
        <EditableRow 
          colSpan={colSpan}
          EditContent={<EditContent item={editItem} />}
        />
      )}
    </>
  )
});

const GroupRow = memo(({ variant, editItem, editableContent, handleChangeEditItem, isItemSelected, enableSelect, handleCheckboxClick, RowItem, colSpan, item, index, selected }) => (
  <>
    <TableRow>
      <TableCell
        colSpan={colSpan}
        sx={styles[variant]?.groupedRowLabel}
      >
        <Typography
          color="textSecondary"
          variant="h6"
        >
          {(item?.label || item?.key)?.charAt(0).toUpperCase() + (item?.label || item?.key).slice(1)}
          {' '}
          ({item?.values?.length})
        </Typography>
      </TableCell>
    </TableRow>
    {item?.values?.map((row, index) => {
      const isItemSelected = selected?.indexOf(row.id) !== -1;
      return(
        <NormalRow 
          key={row?.id?.toString() || index.toString()}
          isItemSelected={isItemSelected}
          enableSelect={enableSelect}
          handleCheckboxClick={handleCheckboxClick}
          RowItem={RowItem}
          item={row}
          index={index}
          colSpan={colSpan}
          selected={selected}
          variant={variant}
          editItem={editItem}
          editableContent={editableContent}
          handleChangeEditItem={handleChangeEditItem}
        />
      )
    })}
  </>
));


TableBodyRow.propTypes = {
  id: PropTypes.any,
  isItemSelected: PropTypes.bool,
  handleCheckboxClick: PropTypes.func,
  RowItem: PropTypes.any,
  enableSelect: PropTypes.bool,
  isGroupChecked: PropTypes.bool,
  colSpan: PropTypes.number,
  item: PropTypes.object,
  index: PropTypes.number,
  selected: PropTypes.array,
  variant: PropTypes.string,
  editItem: PropTypes.any,
  editableContent: PropTypes.any,
  handleChangeEditItem: PropTypes.func,
};

function TableBodyRow ({
  isItemSelected,
  handleCheckboxClick,
  RowItem,
  enableSelect,
  isGroupChecked,
  colSpan,
  item,
  index,
  selected,
  variant,
  editItem,
  editableContent,
  handleChangeEditItem
}) {
  return !isGroupChecked ? (
    <NormalRow 
      isItemSelected={isItemSelected}
      enableSelect={enableSelect}
      handleCheckboxClick={handleCheckboxClick}
      RowItem={RowItem}
      colSpan={colSpan}
      item={item}
      index={index}
      selected={selected}
      variant={variant}
      editItem={editItem}
      editableContent={editableContent}
      handleChangeEditItem={handleChangeEditItem}
    />
  ) :(
    <GroupRow
      isItemSelected={isItemSelected}
      enableSelect={enableSelect}
      handleCheckboxClick={handleCheckboxClick}
      RowItem={RowItem} 
      colSpan={colSpan}
      item={item}
      index={index}
      selected={selected}
      variant={variant}
      editItem={editItem}
      editableContent={editableContent}
      handleChangeEditItem={handleChangeEditItem}
    />
  )
};

export default memo(TableBodyRow);
