import { createSlice } from '@reduxjs/toolkit';

// api
import { AuthV1API, UserV1API } from '../../api';
// utils
import {isEmpty} from '../../utils';
import { startLoading, hasError, handleResponse, dataListState, dataState } from '../constant';

// ----------------------------------------------------------------------

const initialState = {
  // common
  isLoading: false,
  error: false,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    startLoading,
    hasError,
    handleResponse,
    // STATE CLEAN UP 
    authStateCleaner(state, action) {
      const field = action.payload;
      if(typeof field === "string"){
        state[field] = initialState[field];
      }
    },
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { authStateCleaner } = slice.actions;

// -----------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------

export const createAccount = ({  ...payload }) => async (dispatch) => {
  try {
    dispatch(slice.actions.startLoading());
    return await AuthV1API.createAccount({ ...payload });
  } catch (error) {
    dispatch(slice.actions.hasError({ error }))
  }
};

export const login = ({  ...payload }) => async (dispatch) => {
  try {
    dispatch(slice.actions.startLoading());
    return await AuthV1API.login({ ...payload });
  } catch (error) {
    dispatch(slice.actions.hasError({ error }))
  }
};

export const changePassword = ({  ...payload }) => async (dispatch) => {
  try {
    dispatch(slice.actions.startLoading());
    return await AuthV1API.changePassword({ ...payload });
  } catch (error) {
    dispatch(slice.actions.hasError({ error }))
  }
};

export const changeEmail = ({  ...payload }) => async (dispatch) => {
  try {
    dispatch(slice.actions.startLoading());
    return await AuthV1API.changeEmail({ ...payload });
  } catch (error) {
    dispatch(slice.actions.hasError({ error }))
  }
};

export const forgotPassword = ({  ...payload }) => async (dispatch) => {
  try {
    dispatch(slice.actions.startLoading());
    return await AuthV1API.forgotPassword({ ...payload });
  } catch (error) {
    dispatch(slice.actions.hasError({ error }))
  }
};
