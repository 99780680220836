import { DefaultNavbar } from "../../components";
import { THEME } from "../../assets";

// Routes
import MenuConfig from "./MenuConfig";

function MainNavbar() {
  const { menu, action } = MenuConfig();

  return (
    <DefaultNavbar
      routes={menu}
      action={action}
      transparent
      relative
      sx={{
        position: 'fixed',
        top: 0,
        backgroundColor: "lavender", 
        backgroundImage: "linear-gradient(0deg, lavender, white)",
        // backgroundColor: "transparent", 
        // backgroundImage: "linear-gradient(195deg, #42424a, #191919)",
        paddingLeft: { lg: "5rem!important" },
        paddingRight: { lg: "5rem!important" },
        boxShadow: THEME.boxShadows['sm'],
        minWidth: "100%",
        zIndex: 3
      }}
    />
  );
};

export default MainNavbar;
