// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_PAGE = {
  home: '/',
  aboutUs: 'about-us',

  // AUTH
  login: path(ROOTS_AUTH, '/login'),
  createAccount: path(ROOTS_AUTH, '/create-account'),
  forgotPassword: path(ROOTS_AUTH, '/forgot-password'),

  // DASHBOARD
  // dashboard: ROOTS_DASHBOARD,
  invoice: {
    list: '/invoice/list',
    view: '/invoice/view',
    create: '/invoice/create',
    edit: '/invoice/edit',
  },
  customer: {
    list: '/customer/list',
    addNew: '/customer/add-new',
    edit: '/customer/edit',
  },
  fromContact: {
    list: '/from-contact/list',
    addNew: '/from-contact/add-new',
    edit: '/from-contact/edit',
  },
  bank: {
    list: '/bank/list',
    addNew: '/bank/add-new',
    edit: '/bank/edit',
  },
  settings: {
    root: '/settings',
    profile: '/settings?tab=profile',
    invoice: '/settings?tab=invoice',
    password: '/settings?tab=password',
    email: '/settings?tab=email',
    bank: '/settings?tab=bank',
  },
  
  // OTHER
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  page404: '/404',
  page500: '/500'
};