import { memo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, FormControlLabel, Menu, MenuItem } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const MultiSelect = memo(({ item, handleChangeFilterValues, selected }) => {
  const { label, displayValue, optionId, options } = item;
  const anchorRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);

  const handleOpenMenu = () => {
    setOpenMenu(true);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };
 

  return (
    <>
      <Button
        color="inherit"
        endIcon={<ExpandMoreIcon fontSize="small" />}
        onClick={handleOpenMenu}
        ref={anchorRef}
      >
        {label}
      </Button>
      <Menu
        anchorEl={anchorRef.current}
        onClose={handleCloseMenu}
        open={openMenu}
        PaperProps={{ style: { width: 250 } }}
      >
        {options?.map((option, index) => {
          const isChecked = Boolean(selected?.find((ele) => ele[optionId] === option[optionId]));
          return (
          <MenuItem key={index.toString()}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={isChecked}
                  onChange={(e) => handleChangeFilterValues(e, item, option)}
                  // value={option[displayValue]}
                />
              )}
              label={option[displayValue]}
              sx={{
                flexGrow: 1,
                mr: 0
              }}
            />
          </MenuItem>
        )})}
      </Menu>
    </>
  );
});

MultiSelect.propTypes = {
  item: PropTypes.object.isRequired,
  handleChangeFilterValues: PropTypes.func,
  selected: PropTypes.array
};

