import { APIService, API_CONSUMER } from '../';
const BaseURL = `${API_CONSUMER}/api/v1/static`;

export default {
  getStaticRecordList: async ({ ...payload }) =>  await APIService({ payload, url: `${BaseURL}/getStaticRecordList`, method: 'POST' }),
  
  insertNewStaticRecord: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/insertNewStaticRecord`, method: 'POST' }),
  
  getLanguageList: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/getLanguageList`, method: 'POST' }),

  getCurrencyList: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/getCurrencyList`, method: 'POST' }),
  
  getCountryListOrData: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/getCountryListOrData`, method: 'POST' }),

  getStateListOrData: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/getStateListOrData`, method: 'POST' }),

  getSuburbListOrData: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/getSuburbListOrData`, method: 'POST' }),
};