import axios from 'axios';
import { toast } from 'react-toastify';

import { localStorage } from '../../utils';

export default async function CheckError(error) {
  // console.log(error, error.response, error.response.status)
  // console.log('HttpClient: ',error);
  // The request was made and the server responded with a status code
  // that falls out of the range of 2xx
  if (axios.isCancel(error)) {
    console.log('Request cancelled', error.message);
  } else if (error.response) {
    if (error.response.status === 500) {
      toast.error('Internal server error');
    } else if (error.response.status === 502) {
      toast.error('Operation failed! Bad Gateway Error.');
    } else if (error.response.status === 404) {
      toast.error('Resource not found');
    } else if (error.response.status === 401) {
      toast.error("Operation Failed due to Unauthorized user.  kindly login back");
      localStorage.remove('accessToken');
      localStorage.remove('refreshToken');
      window.location.replace('/auth/login');
    } else if (error.response.status === 400) {
      console.log('Bad request', error);  
    }
  } else if (error.request) {
    console.log('error.request', error.request);
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
  } else if (error.response === undefined) {
    toast.error("Please check your internet connectivity!");
  } else {
    // Something happened in setting up the request that triggered an Error
    toast.error(error.message);
  }
}
