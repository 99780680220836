import { Fragment, Suspense, lazy, useMemo } from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';

// material
import { Container } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

// components
import { Page, LoadingScreen, MKBreadcrumbs, MKButton } from '../components';

// utils
import {isEmpty} from '../utils';
import { PATH_PAGE } from './paths';

const Loadable = (Component, params) => (props) => {
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  const values = useMemo(() => ({
    title: props?.title || params?.title || "",
    subtitle: props?.subtitle || params?.subtitle || "",
    container: !isEmpty(props?.container) ? props?.container : !isEmpty(params?.container) ? params?.container : true,
    stretch: !isEmpty(props?.stretch) ? props?.stretch : !isEmpty(params?.stretch) ? params?.stretch : true,
    breadcrumb: {
      show: !isEmpty(props?.breadcrumb?.show) ? props?.breadcrumb?.show : !isEmpty(params?.breadcrumb?.show) ? params?.breadcrumb?.show : false,
      heading: props?.breadcrumb?.heading || params?.breadcrumb?.heading || "",
      links: props?.breadcrumb?.links || params?.breadcrumb?.links || [],
      backIcon: !isEmpty(props?.breadcrumb?.backIcon) ? props?.breadcrumb?.backIcon : !isEmpty(params?.breadcrumb?.backIcon) ? params?.breadcrumb?.backIcon : false,
      action: props?.breadcrumb?.action || params?.breadcrumb?.action || null,
      linkButton: props?.breadcrumb?.linkButton || params?.breadcrumb?.linkButton || null,
    }
  }) ,[props, params]);
  
  const FinalComponent = () => (
    <Fragment>
      {values?.breadcrumb?.show && (
        <MKBreadcrumbs 
          heading={values?.breadcrumb?.heading}
          routes={values?.breadcrumb?.links}
          action={
            !isEmpty(values?.breadcrumb?.action) ? values?.breadcrumb?.action : (
              !isEmpty(values?.breadcrumb?.linkButton) ? (
                <MKButton
                  variant="contained"
                  color="primary"
                  component={RouterLink}
                  to={values?.breadcrumb?.linkButton?.href}
                  {...(values?.breadcrumb?.linkButton?.icon !== "hide") && { 
                    startIcon: values?.breadcrumb?.linkButton?.icon || <AddIcon />
                  }}
                  
                >
                  {values?.breadcrumb?.linkButton?.label || ""}
                </MKButton>
              ) : null
            )
          }
          backIcon={values?.breadcrumb?.backIcon}
        />
      )}
      <Component {...values} />
    </Fragment>
  )

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Page title={values?.title} subtitle={values?.subtitle}>
        {values?.container ? (
          <Container 
            maxWidth={values?.stretch ? false : 'xl'} 
            sx={{ 
              mt: 5,
              minWidth: '100%',
              paddingLeft: { lg: "5rem!important" },
              paddingRight: { lg: "5rem!important" },            
            }}
          >
            <FinalComponent />
          </Container>
        ) : ( 
          <FinalComponent /> 
        )}
      </Page>
    </Suspense>
  );
};

// ----------------------------------------------------------------------------
// Home
// ----------------------------------------------------------------------------
export const Home = Loadable(
  lazy(() => import('../pages/home')),
  { title: "Home", container: false }
);

// ----------------------------------------------------------------------------
// AboutUs
// ----------------------------------------------------------------------------
export const AboutUs = Loadable(
  lazy(() => import('../pages/about-us')),
  { title: "About us", container: false }
);

// ----------------------------------------------------------------------------
// Contact
// ----------------------------------------------------------------------------
export const Contact = Loadable(
  lazy(() => import('../pages/contact')),
  { title: "Contact" }
);

// ----------------------------------------------------------------------------
// Customer
// ----------------------------------------------------------------------------
export const InvoiceList = Loadable(
  lazy(() => import('../pages/invoice/list')),
  { 
    title: "Invoices: List",
    breadcrumb: {
      show: true,
      heading: "Invoice List",
      links: [
        { label: 'Home', route: PATH_PAGE.home },
        { label: 'Invoice', route: PATH_PAGE.invoice.list },
        { label: 'List' }
      ]
    }
  }
);
export const InvoiceCreate = Loadable(
  lazy(() => import('../pages/invoice/create')),
  { 
    title: "Invoice: Create new invoice",
    breadcrumb: {
      show: true,
      heading: "Create new invoice",
      links: [
        { label: 'Home', route: PATH_PAGE.home },
        { label: 'Invoices', route: PATH_PAGE.invoice.list },
        { label: "New Invoice" },
      ]
    }
  }
);
export const InvoiceEdit = Loadable(
  lazy(() => import('../pages/invoice/create')),
  { 
    title: "Invoice: Edit invoice",
    breadcrumb: {
      show: true,
      heading: "Edit invoice",
      links: [
        { label: 'Home', route: PATH_PAGE.home },
        { label: 'Invoices', route: PATH_PAGE.invoice.list },
        { label: "Edit Invoice" },
      ]
    }
  }
);
export const InvoiceView = Loadable(
  lazy(() => import('../pages/invoice/view')),
  { 
    title: "Invoice View",
    breadcrumb: {
      show: true,
      heading: "Invoice View",
      links: [
        { label: 'Home', route: PATH_PAGE.home },
        { label: 'Invoices', route: PATH_PAGE.invoice.list },
        { label: "View" },
      ]
    }
  }
);

// ----------------------------------------------------------------------------
// Customer
// ----------------------------------------------------------------------------
export const CustomerList = Loadable(
  lazy(() => import('../pages/customer/list')),
  { 
    title: "Customers: List",
    breadcrumb: {
      show: true,
      heading: "Customer List",
      links: [
        { label: 'Home', route: PATH_PAGE.home },
        { label: 'Customer', route: PATH_PAGE.customer.list },
        { label: 'List' }
      ]
    }
  }
);
export const CustomerAddNew = Loadable(
  lazy(() => import('../pages/customer/add-new')),
  { 
    title: "Customer: Add new customer",
    breadcrumb: {
      show: true,
      heading: "Add New Customer",
      links: [
        { label: 'Home', route: PATH_PAGE.home },
        { label: 'Customer', route: PATH_PAGE.customer.list },
        { label: "Add Customer Form" },
      ]
    }
  }
);
export const CustomerEdit = Loadable(
  lazy(() => import('../pages/customer/add-new')),
  { 
    title: "Customer: Edit Details",
    breadcrumb: {
      show: true,
      heading: "Edit Customer Details",
      links: [
        { label: 'Home', route: PATH_PAGE.home },
        { label: 'Customer', route: PATH_PAGE.customer.list },
        { label: "Customer Edit Form" },
      ]
    }
  }
);


// ----------------------------------------------------------------------------
// From Contact
// ----------------------------------------------------------------------------
export const FromContactList = Loadable(
  lazy(() => import('../pages/from-contact/list')),
  { 
    title: "From Contact: List",
    breadcrumb: {
      show: true,
      heading: "From Contact List",
      links: [
        { label: 'Home', route: PATH_PAGE.home },
        { label: 'From Contacts', route: PATH_PAGE.fromContact.list },
        { label: 'List' }
      ]
    }
  }
);
export const FromContactAddNew = Loadable(
  lazy(() => import('../pages/from-contact/add-new')),
  { 
    title: "From Contact: Add new contact",
    breadcrumb: {
      show: true,
      heading: "Add From Contact",
      links: [
        { label: 'Home', route: PATH_PAGE.home },
        { label: 'From Contacts', route: PATH_PAGE.fromContact.list },
        { label: "From Contact Form" },
      ]
    }
  }
);
export const FromContactEdit = Loadable(
  lazy(() => import('../pages/from-contact/add-new')),
  { 
    title: "From Contact: Edit Details",
    breadcrumb: {
      show: true,
      heading: "Edit From Contact Details",
      links: [
        { label: 'Home', route: PATH_PAGE.home },
        { label: 'From Contacts', route: PATH_PAGE.fromContact.list },
        { label: "From Contact Form" },
      ]
    }
  }
);


// ----------------------------------------------------------------------------
// Customer
// ----------------------------------------------------------------------------
export const BankList = Loadable(
  lazy(() => import('../pages/bank/list')),
  { 
    title: "Bank: List",
    breadcrumb: {
      show: true,
      heading: "Bank List",
      links: [
        { label: 'Home', route: PATH_PAGE.home },
        { label: 'Bank Accounts', route: PATH_PAGE.bank.list },
        { label: 'List' }
      ]
    }
  }
);
export const BankAddNew = Loadable(
  lazy(() => import('../pages/bank/add-new')),
  { 
    title: "Bank: Add new Bank Account",
    breadcrumb: {
      show: true,
      heading: "Add New Bank Account",
      links: [
        { label: 'Home', route: PATH_PAGE.home },
        { label: 'Bank Accounts', route: PATH_PAGE.bank.list },
        { label: "Add Bank Account Form" },
      ]
    }
  }
);
export const BankEdit = Loadable(
  lazy(() => import('../pages/bank/add-new')),
  { 
    title: "Bank: Edit Details",
    breadcrumb: {
      show: true,
      heading: "Edit Bank Account",
      links: [
        { label: 'Home', route: PATH_PAGE.home },
        { label: 'Bank Accounts', route: PATH_PAGE.bank.list },
        { label: "Bank Account Edit Form" },
      ]
    }
  }
);


// ----------------------------------------------------------------------------
// Settings
// ----------------------------------------------------------------------------
export const Settings = Loadable(
  lazy(() => import('../pages/settings')),
  { 
    title: "Settings",
    breadcrumb: {
      show: true,
      heading: "Settings",
      links: [
        { label: 'Home', route: PATH_PAGE.home },
        { label: 'Settings', route: PATH_PAGE.settings.root },
        // { label: "" },
      ]
    }
  }
); 

// ----------------------------------------------------------------------------
// Authentication
// ----------------------------------------------------------------------------
export const Login = Loadable(
  lazy(() => import('../pages/auth/login')),
  { title: "Login" }
);
export const CreateAccount = Loadable(
  lazy(() => import('../pages/auth/create-account')),
  { title: "Create Account" }
);
export const ForgotPassword = Loadable(
  lazy(() => import('../pages/auth/forgot-password')),
  { title: "Forgot Password" }
);

// ----------------------------------------------------------------------------
// Maintenance
// ----------------------------------------------------------------------------
export const NotFound = Loadable(
  lazy(() => import('../pages/extra/error-404')),
  { title: "Page Not Found" }
);
export const Maintenance = Loadable(
  lazy(() => import('../pages/extra/maintenance')),
  { title: "Under Maintenance" }
);
export const Error500 = Loadable(
  lazy(() => import('../pages/extra/error-500')),
  { title: "Error 500" }
);
export const ComingSoon = Loadable(
  lazy(() => import('../pages/extra/coming-soon')),
  { title: "Coming Soon" }
);