import { getDateInDDMMYYYY, isEmpty } from '../../../utils';
import { styles } from './styles';

const getFromOrToContact = ({ label, values }) => {
  return [
                          { text: label || "",  color: '#7b809a', bold: true, fontSize: 10, alignment: 'left',  margin: [0, 20, 0, 5] },
    ...(values?.map(v => ({ text: `${v || ''}`, color: '#344767', bold: true, fontSize: 10, alignment: 'left' })) || []),
  ]
}; 
const getInvoiceBasic = ({ cols }) => {
  return [...(cols?.map((c) => (
      {
        columns: [
          { text: c?.label || "", color: '#7b809a', bold: true, fontSize: 10, alignment: 'right', margin: [0, 0, 0, 5] },
          { text: c?.value || "", color: '#7b809a', bold: true, fontSize: 10, alignment: 'right', margin: [0, 0, 0, 5], width: 80 }
        ],
      }
    ))
  )]
};

const getInvoiceTotal = ({ cols }) => {
  return [...(cols?.map((c) => (
      {
        columns: [
          { text: c?.label || "", color: '#7b809a', bold: true, fontSize: 10, alignment: 'right', margin: [0, 0, 0, 5] },
          { text: c?.value || "", color: '#344767', bold: true, fontSize: 10, alignment: 'right', margin: [0, 0, 0, 5], width: 100 }
        ],
      }
    ))
  )]
};
export default function InvoicePdfDD(params) {
  const { invoice_items, logo } = params;
  const inv = params;
  const FC = inv?.from_contact;
  const CST = inv?.customer;
  const II = invoice_items || [];
  const showTax = Boolean(inv?.show_tax);

  const buildTableBody = () => {
    const columns = showTax ? ["Item", "Description", "Rate", "Qty", "Tax(%)", "Amount"] : ["Item", "Description", "Rate", "Qty", "Amount"];
    const valueKeys = showTax ? ["item_type_name", "description", "rate", "quantity", "tax", "amount"] : ["item_type_name", "description", "rate", "quantity", "amount"];
    
    const body = [];
    body.push(columns?.map((head) => ({
      text: head, 
      style: { ...styles.HeaderStyle1, alignment: ["Rate", "Qty", "Tax(%)", "Amount"]?.includes(head) ? "right" : "left" }
    })));

    (II || [])?.forEach((row) => {
      const dataRow = [];
      
      valueKeys.forEach((col) => {
        if(['rate','amount'].includes(col)) {
          dataRow.push({ 
            text: `${inv?.currency_symbol || ""}${Number(row?.[col]).toFixed(2)}`, 
            style: { ...styles.BodyRowStyle1, alignment: 'right' } 
          });
        } 
        else if(['tax'].includes(col)) {
          dataRow.push({ 
            text: `${Number(row?.[col]).toFixed(2)}%`,
            style: { ...styles.BodyRowStyle1, alignment: 'right' } 
          });          
        } 
        else {
          dataRow.push({ 
            text: `${row?.[col] || ""}`,
            style: { ...styles.BodyRowStyle1, alignment: col === "quantity" ? 'right' : "left" }
          });
        }
      });
      body.push(dataRow);
    });
    return body;
  };

  const dd = {
    content: [
      {
        columns: [
          [
            ...getFromOrToContact({
              label: 'From:',
              values: [ FC?.name,  `BSB#: ${FC?.business_number || ""}`, `${FC?.email ? `${FC?.email}, ` : ""}${FC?.phone || ""}`, FC?.address, ]
            }),            
            '\n',
            ...getFromOrToContact({
              label: 'To:',
              values: [ CST?.name, `${CST?.email ? `${CST?.email}, ` : ""}${CST?.phone || ""}`, CST?.address, ]
            }),            
          ],
          [
            isEmpty(logo) ? { text: '', width: 120, alignment: "right", margin: [0, 20, 0, 5] } : { image: logo, width: 120, alignment: "right", margin: [0, 20, 0, 5]},
            '\n',
            { text: `${inv?.title || ""}`, color: '#7b809a', width: '*', fontSize: 20, bold: true, alignment: 'right' },
            '\n',
            {
              stack: [
                ...getInvoiceBasic({
                  cols: [
                    { label: 'Invoice #:', value: inv?.invoice_no },
                    { label: 'Invoice Date:', value: getDateInDDMMYYYY(inv?.invoice_date) },
                    { label: 'Due Date:', value: getDateInDDMMYYYY(inv?.due_date) },
                  ],
                }),
              ],
              
            }
          ]
        ]
      },
      '\n','\n',
      {
        layout: {
          hLineWidth(i, node) { return 1; },
          vLineWidth(i, node) { return 1; },
          hLineColor(i, node) { return '#eaeaea'; },
          vLineColor(i, node) { return '#eaeaea'; },
          hLineStyle(i, node) {
            // if (i === 0 || i === node.table.body.length) {
            return null;
            // }
          },
          // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
          // paddingLeft: function(i, node) {
          //   return 10;
          // },
          // paddingRight: function(i, node) {
          //   return 10;
          // },
          paddingTop: function(i, node) {
            return 10;
          },
          paddingBottom: function(i, node) {
            return 10;
          },
          fillColor(rowIndex, node, columnIndex) {
            return rowIndex % 2 === 0 ? '#f1f3f4' : null;
          }
        },
        table: {
          headerRows: 1,
          widths: showTax ? ['12%', '40%', '12%', '12%', '12%', '12%'] : ['15%', '40%', '15%', '15%',  '15%'],
          body: buildTableBody(),
        }
      },
      '\n\n',
      {
        columns: [
          [
            { text: "Notes:",  color: '#7b809a', bold: true, fontSize: 10, alignment: 'left',  margin: [0, 0, 0, 5] },
            { text: `${inv?.notes || ''}`, color: '#344767', bold: true, fontSize: 10, alignment: 'left', margin: [0, 0, 0, 0]  },
            ...(inv?.bank ? 
              getFromOrToContact({
                label: 'PAYMENT INSTRUCTIONS:',
                values: [ 
                  `ACCOUNT HOLDER NAME: ${String(inv?.bank?.account_holder_name || "")?.toUpperCase()}`,
                  `ACCOUNT #: ${String(inv?.bank?.account_number || "")?.toUpperCase()}`,
                  `BSB #: ${String(inv?.bank?.bsb_number || "")?.toUpperCase()}`,
                  `BANK NAME: ${String(inv?.bank?.bank_name || "")?.toUpperCase()}`
                ]
              }) : [])
          ],
          [
            {
              stack: [
                ...getInvoiceTotal({
                  cols: [
                    { label: 'Subtotal:', value: `${inv?.currency_symbol || ""}${Number(inv?.sub_total).toFixed(2)}` },
                    { label: 'Tax Amount:', value: `${inv?.currency_symbol || ""}${Number(inv?.tax_amount).toFixed(2)}`, hLine: true },
                    { 
                      label: `Discount: ${(Number(inv?.discount_type) === 2 && Number(inv?.discount) > 0) ? `(${inv?.discount}%)` : ""}`, 
                      value: 
                        Number(inv?.discount_type) === 2 ? 
                          Number(((inv?.sub_total || 0) + (inv?.tax_amount || 0)) * Number(inv?.discount || 0) / 100).toFixed(2)
                        : `${inv?.currency_symbol || ""}${inv?.discount || "0.00"}`
                    },
                    { label: 'Total:', value: `${inv?.currency_symbol || ""}${Number(inv?.total).toFixed(2)}` },
                  ],
                }),
              ],
            },
          ],
        ],
      },
    ],
    footer: function(currentPage, pageCount) { 
      return {
        columns: [
          { text: "a1invoice.com Powered by a1abilities.com", alignment: 'left', fontSize: 8, color: '#344767', bold: true, margin: [40, 5, 0, 5]},
          { text: currentPage.toString() + ' of ' + pageCount, alignment: 'right', fontSize: 8, color: '#344767', bold: true, margin: [0, 10, 40, 5] },
        ]
      }
    },
    // header: function(currentPage, pageCount, pageSize) {
    //   // you can apply any logic and return any valid pdfmake element
  
    //   return [
    //     { text: 'simple text', alignment: (currentPage % 2) ? 'left' : 'right' },
    //     { canvas: [ { type: 'rect', x: 170, y: 32, w: pageSize.width - 170, h: 40 } ] }
    //   ]
    // },
    defaultStyle: {
      columnGap: 20
    }
  };

  return dd;
}
