/* eslint-disable no-nested-ternary */
import { useState, useEffect, useCallback } from 'react';
import { useRoutes } from 'react-router-dom';

// material
import SuperAdminRoutes from './SuperAdmin';
import MasterUserRoutes from './MasterUser';
import AccountUserRoutes from './AccountUser';
import PubliRoutes from './Public';
import CommonRoutes from './Common';

// hooks
import useAuth from '../hooks/useAuth';
import useConstant from '../hooks/useConstant';

export default function Routes() {
  const [Routes, setRoutes] = useState([]);
  const { isAuthenticated, user } = useAuth();
  const { userRole } = useConstant();
  
  const handleRoutes = useCallback(() => {
    let RoleRoutes = [];
    if (isAuthenticated) {
      RoleRoutes =
        userRole?.isSuperAdmin ? SuperAdminRoutes() :
        userRole?.isMasterUser ? MasterUserRoutes() :
        userRole?.isAccountUser ? AccountUserRoutes() : []
    } else {
      RoleRoutes = PubliRoutes();
    }
    setRoutes([...RoleRoutes, ...CommonRoutes()]);
  }, [isAuthenticated, user]);
  useEffect(() => { handleRoutes(); }, [handleRoutes]);
  return useRoutes(Routes);
};
