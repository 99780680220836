// @mui material components
import Icon from "@mui/material/Icon";
import { PATH_PAGE } from "../../../routes/paths";
import AccountPopover from "../AccountPopover";

const menu = [
  {
    name: "home",
    route: PATH_PAGE.home,
  },
  {
    name: "invoices",
    collapse: [
      { name: "Create Invoice", route: PATH_PAGE.invoice.create },
      { name: "Invoice List", route: PATH_PAGE.invoice.list },
    ],
  },
  {
    name: "customers",
    collapse: [
      { name: "Add Customer", route: PATH_PAGE.customer.addNew  },
      { name: "Customer List", route: PATH_PAGE.customer.list },
    ],
  },
  {
    name: "extra",
    route: PATH_PAGE.aboutUs,
    columns: 2,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "From Contact",
        collapse: [
          {
            name: "Contact List",
            route: PATH_PAGE.fromContact.list,
          },
          {
            name: "Add New Contact",
            route: PATH_PAGE.fromContact.addNew,
          },
        ],
      },      
      {
        name: "Company",
        collapse: [
          {
            name: "About Us",
            route: PATH_PAGE.aboutUs,
          },
        ],
      },
      {
        name: "Bank Account",
        collapse: [
          {
            name: "Account List",
            route: PATH_PAGE.bank.list,
          },
          {
            name: "Add New Account",
            route: PATH_PAGE.bank.addNew,
          },
        ],
      },
    ],
  },
];

const action = () => {
  return (
    <AccountPopover />
  )
};

export default {
  menu,
  action
};