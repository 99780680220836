import { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import { Chip, Divider, Box, Typography } from '@mui/material';

import { MultiSelect } from './MultiSelect';

// ------------------------------------------------------------------

const AppliedFilters = ({ selectedItems, handleDeleteFilterItem }) => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        p: 2
      }}
    >
      {selectedItems?.map((item, index) => (
        (item?.options || [])?.map((option, ind) => (
          <Chip
            key={`${index.toString()}-${ind.toString()}`}
            label={(
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  '& span': {
                    fontWeight: 600
                  }
                }}
              >
                <span> {item?.label} </span>
                :
                {' '}
                { option[item?.displayValue] }
              </Box>
            )}
            // size='small'
            onDelete={() => handleDeleteFilterItem(item, option)}
            sx={{ m: 1 }}
            variant="outlined"
          />
        ))        
      ))}
    </Box>
  )
};

AppliedFilters.propTypes = {
  selectedItems: PropTypes.array,
  handleDeleteFilterItem: PropTypes.func 
};

const NoFilterApplied = () => (
  <Box sx={{ px: 3, py: 2 }}>
    <Typography color="text" variant="overline">
      No filters applied
    </Typography>
  </Box>
);


const FilterBar = ({ items, selectedItems, handleDeleteFilterItem, handleChangeFilterValues, Toolbar }) => (
  <Fragment>   
    <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between'}}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          p: 2
        }}
      >
        {(items || [])?.map((item, index) => (
          <MultiSelect
            key={index.toString()}
            item={item}
            handleChangeFilterValues={handleChangeFilterValues}
            selected={(selectedItems?.find(e => e?.id === item?.id)?.options)}
          />
        ))}
      </Box>
      {Toolbar && (Toolbar)}
    </Box> 
    
    <Divider sx={{ opacity: 1, margin: 0 }} />
    {selectedItems?.length > 0 ? ( <AppliedFilters selectedItems={selectedItems} handleDeleteFilterItem={handleDeleteFilterItem} /> ) : ( <NoFilterApplied /> )}
    <Divider sx={{ opacity: 1, margin: 0 }} />
  </Fragment>
);

FilterBar.propTypes = {
  items: PropTypes.array, 
  selectedItems: PropTypes.array, 
  handleDeleteFilterItem: PropTypes.func, 
  handleChangeFilterValues: PropTypes.func, 
  Toolbar: PropTypes.node
};

export default memo(FilterBar);