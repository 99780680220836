
import { memo } from 'react';
import PropTypes from 'prop-types';
// material
import { TableHead, TableCell, TableRow, Checkbox, TableSortLabel, Box, Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { styles } from './styles';

TableHeader.propTypes = {
  order: PropTypes.oneOf(['asc','desc']),
  orderBy: PropTypes.string,
  headLabel: PropTypes.array,
  rowCount: PropTypes.number,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  enableSort: PropTypes.bool,
  enableSelect: PropTypes.bool,
  enableBulkActions: PropTypes.bool,
  BulkActionBar: PropTypes.any,
  variant: PropTypes.string,
};

function TableHeader({
  order,
  orderBy,
  headLabel,
  rowCount,
  numSelected,
  onRequestSort,
  onSelectAllClick,
  enableSort,
  enableSelect,
  showSelectAllButton,
  enableBulkActions,
  BulkActionBar,
  isEditable,
  variant
}) {
  return (
    <TableHead sx={{ display: "table-header-group" }}>
      {isEditable && (<TableCell padding="checkbox" style={styles[variant]?.tableHeadStyle} />)}
      {enableSelect && (
        <TableCell padding="checkbox" style={styles[variant]?.tableHeadStyle}>
          {showSelectAllButton && (
            <Checkbox 
              indeterminate={(numSelected > 0) && (numSelected) < (rowCount)}
              checked={(rowCount > 0) && ((numSelected) === (rowCount))}
              onClick={onSelectAllClick}
            />
          )}
        </TableCell>
      )}
      {enableBulkActions ? (
        <TableCell colSpan={headLabel.length} style={styles[variant]?.tableHeadStyle}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="overline">
              {numSelected} selected
            </Typography>
            {BulkActionBar && (<BulkActionBar />)}
          </Box>
        </TableCell>
      ) : (headLabel || []).map((head, index) => (
        <TableCell
          key={index?.toString()}
          align={head.align || 'left'}
          sortDirection={orderBy === head?.id ? order : false}
          style={{...styles[variant]?.tableHeadStyle, ...(head?.style || {})}}
        >
          {enableSort ? (
            <TableSortLabel
              hideSortIcon
              active={orderBy === head?.id}
              direction={orderBy === head?.id ? order : 'asc'}
              onClick={onRequestSort(head?.id)}
            >
              {head?.label} 
              {orderBy === head?.id ? (
                <Box sx={{...visuallyHidden}}>
                  {orderBy === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null }
            </TableSortLabel>)
          : head?.label}
        </TableCell>
      ))}
    </TableHead>
  )
};

export default memo(TableHeader);