import { APIService, API_CONSUMER } from '../';

const BaseURL = `${API_CONSUMER}/api/v1/auth`;

export default {
  createAccount: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/createAccount`, method: 'POST' }),

  login: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/login`, method: 'POST' }),

  logout: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/logout`, method: 'POST' }),
  
  refreshtoken: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/refreshtoken`, method: 'POST' }),
  
  changePassword: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/changePassword`, method: 'POST' }),

  changeEmail: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/changeEmail`, method: 'POST' }),
  
  forgotPassword: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/forgotPassword`, method: 'POST' }),
  
  // verifyVerificationCode: async ({ ...payload }) => {
  //   const response = await APIService({ payload, url: `${BaseURL}/verifyVerificationCode`, method: 'POST' });
  //   return response;
  // },

};
