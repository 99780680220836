// @mui material components
import Icon from "@mui/material/Icon";
import { PATH_PAGE } from "../../../routes/paths";
import AccountPopover from "../AccountPopover";

const menu = [
  {
    name: "home",
    route: PATH_PAGE.home,
  },
  {
    name: "invoices",
    collapse: [
      { name: "Create Invoice", route: PATH_PAGE.invoice.create },
      { name: "Manage Invoices", route: PATH_PAGE.invoice.list },
    ],
  },
  {
    name: "customers",
    collapse: [
      { name: "Add Customer", route: PATH_PAGE.customer.addNew  },
      { name: "Manage Customers", route: PATH_PAGE.customer.list },
    ],
  },
  {
    name: "about us",
    route: PATH_PAGE.aboutUs,
  },
];

const action = () => {
  return (
    <AccountPopover />
  )
};

export default {
  menu,
  action
};