import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

// api
import { API_URL, InvoiceV1API } from '../../api';

// utils
import { startLoading, hasError, handleResponse, dataListState, dataState } from '../constant';
import {PdfMakePDF, getBase64ImageFromURL, isEmpty} from '../../utils';

import { InvoicePdfDD } from '../../constants/reports';

// ----------------------------------------------------------------------

const initialState = {
  // common
  isLoading: false,
  error: false,

  // From Contact
  nextInvoiceNumber: dataState,
  fromContactData: dataState,
  fromContactList: dataListState,

  // customers
  invoices: dataListState,
  invoiceData: dataState,
};

const slice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    startLoading,
    hasError,
    handleResponse,
    // STATE CLEAN UP 
    invoiceStateCleaner(state, action) {
      const field = action.payload;
      if(typeof field === "string"){
        state[field] = initialState[field];
      }
    },
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { invoiceStateCleaner } = slice.actions;

// -----------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------

export const getFromContacts = ({ 
  from_contact_id=null, 
  is_active=null,
  sortBy='latest', 
  pageNo=0, 
  searchQuery="",  
  basic=false,
  ...other  
}) => async (dispatch) => {
  const field = !isEmpty(from_contact_id) ? "fromContactData" : "fromContactList";
  const payload = { from_contact_id, is_active, sortBy, pageNo, searchQuery, basic, ...other };
  
  try {
    dispatch(slice.actions.startLoading(field));
    const response = await InvoiceV1API.getFromContacts({ ...payload });    
    dispatch(slice.actions.handleResponse({ 
      field, 
      data: { data: isEmpty(from_contact_id) ? response?.data?.fromContacts :  response?.data?.fromContacts[0], counts: response?.data?.counts }, 
      response
    }));
    return response;
  } catch (error) {
    dispatch(slice.actions.hasError({ field, error }));
  }
};

export const addUpdateFromContact = ({ ...payload }) => async (dispatch) => {
  try {
    dispatch(slice.actions.startLoading());
    const response = await InvoiceV1API.addUpdateFromContact({ ...payload });    
    return response;
  } catch (error) {
    dispatch(slice.actions.hasError({ error }))
  }
};

export const changeFromContactActivation = ({ ...payload }) => async (dispatch) => {
  try {
    dispatch(slice.actions.startLoading());
    const response = await InvoiceV1API.changeFromContactActivation({ ...payload });    
    return response;
  } catch (error) {
    dispatch(slice.actions.hasError({ error }))
  }
};

export const updateFromContactLogo = ({ ...payload }) => async (dispatch) => {
  try {
    dispatch(slice.actions.startLoading());
    const response = await InvoiceV1API.updateFromContactLogo({ ...payload });    
    return response;
  } catch (error) {
    dispatch(slice.actions.hasError({ error }))
  }
};

export const getInvoices = ({   
  invoice_id=null, 
  customer_id=null, 
  from_date= '',
  to_date= '',
  status=null,
  is_active=null,
  is_archived=0,
  sortBy='latest', 
  pageNo=0, 
  searchQuery="",  
  basic=false,
  ...other
}) => async (dispatch) => {
  
  const field = isEmpty(invoice_id) ? "invoices" : "invoiceData";
  const payload = { invoice_id, customer_id, is_active, is_archived, from_date, to_date, sortBy, status, pageNo, searchQuery, basic, ...other };
  
  try {
    dispatch(slice.actions.startLoading(field));
    const response = await InvoiceV1API.getInvoices({ ...payload });    
    dispatch(slice.actions.handleResponse({ 
      field, 
      data: { data: isEmpty(invoice_id) ? response?.data?.invoices :  response?.data?.invoices[0], counts: response?.data?.counts }, 
      response
    }));
    return response;
  } catch (error) {
    dispatch(slice.actions.hasError({ field, error }));
  }
};

export const saveInvoice = ({ ...payload }) => async (dispatch) => {
  try {
    dispatch(slice.actions.startLoading());
    const response = await InvoiceV1API.saveInvoice({ ...payload });
    return response;
  } catch (error) {
    dispatch(slice.actions.hasError({ error }))
  }
};

export const getNextInvoiceNumber = ({ ...payload }) => async (dispatch) => {
  const field = "nextInvoiceNumber";
  try {
    dispatch(slice.actions.startLoading(field));
    const response = await InvoiceV1API.getNextInvoiceNumber({ ...payload });
    dispatch(slice.actions.handleResponse({ field, data: { data: response?.data?.invoice_no }, response }));
    return response;
  } catch (error) {
    dispatch(slice.actions.hasError({ field, error }))
  }
};

export const changeInvoiceStatus = ({ ...payload }) => async (dispatch) => {
  try {
    dispatch(slice.actions.startLoading());
    const response = await InvoiceV1API.changeInvoiceStatus({ ...payload });
    return response;
  } catch (error) {
    dispatch(slice.actions.hasError({ error }))
  }
};

export const archivedOrRestoreInvoice = ({ invoice_id, is_active, ...other }) => async (dispatch) => {
  try {
    dispatch(slice.actions.startLoading());
    const response = await InvoiceV1API.archivedOrRestoreInvoice({ invoice_id, is_active, ...other });
    return response;
  } catch (error) {
    dispatch(slice.actions.hasError({ error }))
  }
};

export const copyInoice = ({ invoice_id, ...other }) => async (dispatch) => {
  try {
    dispatch(slice.actions.startLoading());
    const response = await InvoiceV1API.copyInoice({ invoice_id, ...other });
    return response;
  } catch (error) {
    dispatch(slice.actions.hasError({ error }))
  }
};

export const removePayment = ({ payment_id, ...other }) => async (dispatch) => {
  try {
    dispatch(slice.actions.startLoading());
    const response = await InvoiceV1API.removePayment({ payment_id, ...other });
    return response;
  } catch (error) {
    dispatch(slice.actions.hasError({ error }))
  }
};

export const downloadInvoicePDF = ({ invoice_id, ...other }) => async (dispatch) => {
  try {
    dispatch(slice.actions.startLoading());
    const { isSuccess, message, data } = await dispatch(getInvoices({ invoice_id }));
    if(isSuccess) {
      const inv = data?.invoices[0];
      const params  = { filename: inv?.invoice_no, logo: null, ...inv };
      
      if (!isEmpty(inv?.from_contact?.logo_image) && Boolean(inv?.show_logo)) {
        const base64Img = await getBase64ImageFromURL(`${API_URL}/api/v1/downloadFile?path=fromContactLogo/${inv?.from_contact?.logo_image}`);
        params.logo = base64Img || null;
      };
      await PdfMakePDF({ CreateDD: InvoicePdfDD, params, action: 'open' });
    } else {
      toast.error(message);
    };
    const response = await InvoiceV1API.removePayment({ payment_id, ...other });
    return response;
  } catch (error) {
    dispatch(slice.actions.hasError({ error }))
  }
};