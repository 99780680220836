import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import { AuthV1API, UserV1API } from '../api';

// components
import { localStorage, isEmpty, parseJwt } from '../utils';
// import { forceDisconnectOnSocket, forceConnectOnSocket } from '../api/config/Socket';
// ----------------------------------------------------------------------

const setLocalStorage = ({ user, accessToken, refreshToken }) => {
  localStorage.setAll([
    // { key: 'user', value: user }, 
    { key: 'ucrId', value: user?.role_id }, 
    { key: 'accessToken', value: accessToken }, 
    { key: 'refreshToken', value: refreshToken }, 
  ]);
};

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  parsedToken: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, parsedToken } = action.payload;
    return {
      ...state,
      isInitialized: true,
      isAuthenticated,
      user,
      parsedToken
    };
  },
  UPDATE: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  RESET: (state, action) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    parsedToken: null
  }),
  UPDATE_PARSED_TOKEN: (state, action) => {
    const { parsedToken } = action.payload;
    return {
      ...state,
      parsedToken
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  initialize: () => Promise.resolve(),
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  changeCurrentRole: () => Promise.resolve(),
  refreshToken: () => Promise.resolve(),
  updateUser: () => Promise.resolve(),
  updateParsedToken: () => Promise.resolve(),
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const resetState = () => {
    localStorage.remove('accessToken');
    localStorage.remove('refreshToken');
    localStorage.remove('ucrId');
    dispatch({ type: 'RESET' });
  };

  const initializeState = ({ user = null, parsedToken = null, isAuthenticated = false }) => {
    dispatch({ type: 'INITIALIZE', payload: { user, isAuthenticated, parsedToken } });
  };

  const updateUser = ({ user }) => {
    dispatch({ type: 'UPDATE', payload: { user } });
  };
  
  const updateParsedToken = ({ parsedToken }) => {
    dispatch({ type: 'UPDATE_PARSED_TOKEN', payload: { parsedToken } });
  };
  
  const initialize = async () => {
    try {
      const accessToken = localStorage.get('accessToken');
      const parsedToken = parseJwt(accessToken);
      if (accessToken) {
        const { isSuccess, data } = await UserV1API.getUserData({});
        if(isSuccess){
          const { user } = data;
          const ucrId = localStorage.get('ucrId');

          if(ucrId && Number(ucrId) > 0) {
            const defaultRole = user?.user_roles?.find(r => r.id === Number(ucrId));
            if(defaultRole){
              user.role_id = Number(ucrId)
              user.role_name = defaultRole?.name;
            } else {
              localStorage.set({ key: 'ucrId', value: user?.role_id });
            }
          } else {
            localStorage.set({ key: 'ucrId', value: user?.role_id });
          }
          initializeState({ user, parsedToken, isAuthenticated: true });
        } else {
          initializeState({});
        }
      } else {
        initializeState({});
      }
    } catch (err) {
      console.error(err);
      initializeState({});
    }
  };
  
  useEffect(() => {    
    initialize();
  }, []);

  const login = async (response) => {
    const { user, accessToken, refreshToken } = response;
    const parsedToken = parseJwt(accessToken);
    setLocalStorage({ user, accessToken, refreshToken });    
    updateUser({ user });
    updateParsedToken({ parsedToken });
    // forceConnectOnSocket();
    return user;
  };

  const logout = async () => {
    const accessToken = localStorage.get('accessToken');
    if(!isEmpty(accessToken)){
      // forceDisconnectOnSocket();
      await AuthV1API.logout({});
    }
    resetState();
  };

  const changeCurrentRole = ({ ucrId }) => {
    localStorage.set({ key: 'ucrId', value: ucrId });
  };

  const refreshToken = async () => {    
    const refreshToken = localStorage.get('refreshToken');
    const { data } = await AuthV1API.refreshtoken({ refreshToken });
    await login(data);    
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        initialize,
        login,
        logout,
        changeCurrentRole,
        refreshToken,
        updateUser,
        updateParsedToken
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

const AuthConsumer = AuthContext.Consumer;
export { AuthConsumer, AuthContext, AuthProvider }