import { memo } from 'react';
import PropTypes from 'prop-types';
// material
import { Box, Paper, Typography } from '@mui/material';

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
  searchQuery: PropTypes.string
};

function SearchNotFound({ searchQuery = '', ...other }) {
  return (
    <Box {...other}>
      <Typography gutterBottom align="center" variant="h6">
        Not found
      </Typography>
      <Typography variant="body2" align="center">
        No results found for &nbsp;
        <strong>&quot;{searchQuery}&quot;</strong>. Try checking for typos or using
        complete words.
      </Typography>
    </Box>
  );
};

export default memo(SearchNotFound);
