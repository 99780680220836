// @mui material components
import Icon from "@mui/material/Icon";

// routes
import { PATH_PAGE } from '../../../routes/paths';

const menu = [
  {
    name: "home",
    route: PATH_PAGE.home,
  },
  {
    name: "Create Invoice",
    route: PATH_PAGE.invoice.create,
  },
  {
    name: "about us",
    route: PATH_PAGE.aboutUs,
  },
];

const action = {
  type: "internal",
  route: PATH_PAGE.login,
  label: "Login",
  color: "dark",
};

export default {
  menu,
  action
};