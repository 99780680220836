import { Navigate } from 'react-router-dom';
import MainLayout from '../layouts/main';

import GuestGuard from '../guards/GuestGuard';
import { PATH_PAGE } from './paths';

// components
import { Home, AboutUs, ForgotPassword, Login, NotFound, Error500, Maintenance, ComingSoon, CreateAccount } from './helpers';

export default function CommonRoutes() {
  return [    
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { index: true, element: <Home /> },  
        { path: '/about-us', element: <AboutUs /> },  
        // { path: '/contact', element: <Contact /> },  
        { 
          path: 'auth', 
          children: [
            { index: true, element: <Navigate to={PATH_PAGE.login} replace /> },  
            {
              path: 'login',
              element: (
                <GuestGuard>
                  <Login />
                </GuestGuard>
              )
            },
            {
              path: 'create-account',
              element: (
                <GuestGuard>
                  <CreateAccount />
                </GuestGuard>
              )
            },            
            { path: 'forgot-password', element: <ForgotPassword /> },
          ]
        },               
        { path: '500', element: <Error500 /> },
        { path: '404', element: <NotFound  /> },
      ]
    },
    {
      path: '*',
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },        
        { path: 'maintenance', element: <Maintenance /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    }
  ];
}
