import { APIService, API_CONSUMER } from '../';
const BaseURL = `${API_CONSUMER}/api/v1/setting`;

export default {
  getSettings: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/getSettings`, method: 'POST' }),

  updateSetting: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/updateSetting`, method: 'POST' }),

  addUpdateBankAccount: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/addUpdateBankAccount`, method: 'POST' }),
  
  changeBankAccountActivation: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/changeBankAccountActivation`, method: 'POST' }),

  getBankAccounts: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/getBankAccounts`, method: 'POST' }),

};
