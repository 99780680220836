import { memo } from 'react';
import { TableRow, TableBody, TableCell } from '@mui/material';

import SearchNotFound from './SearchNotFound';
import NotFound from './NotFound';
import LoadingMessage from './LoadingMessage';

const NotFoundInTable = ({ isLoading = false, searchQuery ='', colSpan = 0 }) => (
  <TableBody>
    <TableRow>
      <TableCell align="center" colSpan={colSpan} sx={{ py: 3 }}>
        {isLoading ? (<LoadingMessage />) :
        (searchQuery 
          ? <SearchNotFound searchQuery={searchQuery} /> 
          : <NotFound /> 
        )}
      </TableCell>
    </TableRow>
  </TableBody>
);

export default memo(NotFoundInTable);