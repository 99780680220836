import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  isSidebarOpen: false,
  isMobile: false,
  isFullScreenEnabled: false,
};

const slice = createSlice({
  name: 'layout',
  initialState,
  reducers: {

    toggleIsSidebarOpen(state, action) {
      const value = action.payload;
      if(value === false || value === true){
        state.isSidebarOpen = value; 
      } else {
        state.isSidebarOpen = !state.isSidebarOpen;
      }
    },
    
    toggleIsMobile(state, action) {
      const value = action.payload;
      if(value === false || value === true){
        state.isMobile = value; 
      } else {
        state.isMobile = !state.isMobile;
      }
    },

    toggleIsFullScreenEnabled(state, action) {
      const value = action.payload;
      if(value === false || value === true){
        state.isFullScreenEnabled = value; 
      } else {
        state.isFullScreenEnabled = !state.isFullScreenEnabled;
      }
    },
    
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { toggleIsSidebarOpen, toggleIsMobile, toggleIsFullScreenEnabled } = slice.actions;

 