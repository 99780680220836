import { Paper, Typography } from '@mui/material';
import { memo } from 'react';

const NotFound = ({ ...other }) => {
  return (
    <Paper sx={{boxShadow: "none"}} {...other}>
      <Typography gutterBottom align="center" variant="h6">
        Not found
      </Typography>
    </Paper>
  )
}

export default memo(NotFound);