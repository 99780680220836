import useAuth from './useAuth';
// ----------------------------------------------------------------------

export default function useConstant() {
  const { user } = useAuth();
  
  return {
    userRole: {
      isSuperAdmin: user?.role_id === 1,
      isMasterUser: user?.role_id === 2,
      isAccountUser: user?.role_id === 3,
    }
  }
};
