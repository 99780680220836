import { createSlice } from '@reduxjs/toolkit';

// api
import {SettingV1API} from '../../api';

// utils
import { startLoading, hasError, handleResponse, dataListState, dataState, defaultState } from '../constant';
import {isEmpty} from '../../utils';
import { toast } from 'react-toastify';

// ----------------------------------------------------------------------

const initialState = {
  // common
  isLoading: false,
  error: false,

  // bank account
  bankAccountData: dataState,
  bankAccountList: dataListState,

  // setting
  settings: dataListState,
  defaultCurrency: dataState,
  defaultDateFormat: dataState,
  defaultBankAccount: dataState,
  defaultInvoiceNotes: dataState,
  invoiceNumberFormat: dataState,
};

const slice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    startLoading,
    hasError,
    handleResponse,
     
    // STATE CLEAN UP 
    settingStateCleaner(state, action) {
      const field = action.payload;
      if(typeof field === "string"){
        state[field] = initialState[field];
      }
    },
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { settingStateCleaner } = slice.actions;

// -----------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------
export const getSettings = ({ field='settings', setting_name, ...other }) => async (dispatch) => {
  const payload = { setting_name, ...other };
  try {
    dispatch(slice.actions.startLoading(field));
    const response = await SettingV1API.getSettings(payload);
    let data;
    if(field === "settings") {
      data = { data: response?.data?.settings, counts: response?.data?.counts };
    } else {
      data = { data: (response?.data?.settings || [])?.find((s) => s?.setting_name === setting_name) };
    }
    dispatch(slice.actions.handleResponse({ field, data, response }));
    return response;
  } catch (error) {
    dispatch(slice.actions.hasError({ field, error }))
  }
};

export const updateSetting = ({ field, value=null }) => async (dispatch, getState) => {
  try {
    const setting = getState()?.setting?.[field];
    const values = setting?.data;
    dispatch(slice.actions.startLoading());

    const response = await SettingV1API.updateSetting({
      setting_value: value,
      setting_name: values?.setting_name,
      user_setting_id: values?.user_setting_id,
      is_json_value: values?.is_json_value,
      setting_id: values?.setting_id
    });
    if(response.isSuccess) {
      toast.success("Setting updated");
    } else {
      toast.error(response.message);
    }    
    return response;
  } catch (error) {
    dispatch(slice.actions.hasError({ error }))
  }
};
// -----------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------

export const getDefaultCurrencySetting = ({  field='defaultCurrency', setting_name="DefaultCurrency" }) => async (dispatch) => {
  return await dispatch(getSettings({ field, setting_name }));  
};

export const updateDefaultCurrencySetting = ({ field="defaultCurrency", value }) => async (dispatch) => {
  try {
    await dispatch(updateSetting({ field, value }));    
    dispatch(getDefaultCurrencySetting({}));
  } catch (error) {
    dispatch(slice.actions.hasError({ error }))
  }
};

export const getDefaultDateFormatSetting = ({  field='defaultDateFormat', setting_name="DefaultDateFormat" }) => async (dispatch) => {
  return await dispatch(getSettings({ field, setting_name }));
};
 
export const updateDefaultDateFormatSetting = ({ field="defaultDateFormat", value }) => async (dispatch) => {
  try {
    await dispatch(updateSetting({ field, value }));    
    dispatch(getDefaultDateFormatSetting({}));
  } catch (error) {
    dispatch(slice.actions.hasError({ error }))
  }
};

export const getDefaultBankAccountSetting = ({  field='defaultBankAccount', setting_name="DefaultBankAccount" }) => async (dispatch) => {
  return await dispatch(getSettings({ field, setting_name }));
};

export const updateDefaultBankAccountSetting = ({ field="defaultBankAccount", value }) => async (dispatch) => {
  try {
    await dispatch(updateSetting({ field, value }));    
    dispatch(getDefaultBankAccountSetting({}));
  } catch (error) {
    dispatch(slice.actions.hasError({ error }))
  }
};

export const getInvoiceFormatSetting = ({  field='invoiceNumberFormat', setting_name="InvoiceNumberFormat" }) => async (dispatch) => {
  return await dispatch(getSettings({ field, setting_name }));
};

export const updateInvoiceFormatSetting = ({ field="invoiceNumberFormat", value }) => async (dispatch) => {
  try {
    await dispatch(updateSetting({ field, value }));    
    dispatch(getInvoiceFormatSetting({}));
  } catch (error) {
    dispatch(slice.actions.hasError({ error }))
  }
};

export const getInvoiceNotesSetting = ({  field='defaultInvoiceNotes', setting_name="InvoiceNotes" }) => async (dispatch) => {
  return await dispatch(getSettings({ field, setting_name }));
};

export const updateInvoiceNotesSetting = ({ field="defaultInvoiceNotes", value }) => async (dispatch) => {
  try {
    await dispatch(updateSetting({ field, value }));    
    dispatch(getInvoiceFormatSetting({}));
  } catch (error) {
    dispatch(slice.actions.hasError({ error }))
  }
};

export const addUpdateBankAccount = ({  ...payload }) => async (dispatch) => {
  try {
    dispatch(slice.actions.startLoading());
    const response = await SettingV1API.addUpdateBankAccount(payload);
    return response;
  } catch (error) {
    dispatch(slice.actions.hasError({ error }))
  }
};

export const changeBankAccountActivation = ({  ...payload }) => async (dispatch) => {
  try {
    dispatch(slice.actions.startLoading());
    const response = await SettingV1API.changeBankAccountActivation(payload);
    return response;
  } catch (error) {
    dispatch(slice.actions.hasError({ error }))
  }
};

export const getBankAccounts = ({ bank_account_id=null, is_active=null, searchQuery='', sortBy='latest', pageNo=0, ...other  }) => async (dispatch) => {
  const field = isEmpty(bank_account_id) ? "bankAccountList" : "bankAccountData";
  const payload = { bank_account_id, is_active, searchQuery, pageNo, sortBy, ...other  }
  try {
    dispatch(slice.actions.startLoading(field));
    const response = await SettingV1API.getBankAccounts(payload);    
    const data = { data: field === "bankAccountList" ? response?.data?.bankAccountList : response?.data?.bankAccountList[0] , counts: response?.data?.counts };
    dispatch(slice.actions.handleResponse({ field, data, response }));
    return response;
  } catch (error) {
    dispatch(slice.actions.hasError({ field, error }))
  }
};
