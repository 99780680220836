import 'react-toastify/dist/ReactToastify.css';
import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { ErrorBoundary } from "react-error-boundary";

// material
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// redux
import { store, persistor } from './redux/store';

// contexts
import { AuthProvider } from './contexts/JWTContext';
// import LocationProvider from './contexts/LocationContext';

// components
import {LoadingScreen} from './components';
import { ErrorFallback } from "./guards/ErrorFallback";


//
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
// import ErrorBoundary from './guards/ErrorBoundary';

const container = document.getElementById("root");
const root = ReactDOMClient.createRoot(container);

root.render(
  <HelmetProvider>
    <ReduxProvider store={store}>
      <PersistGate loading={<LoadingScreen />} persistor={persistor}>
        {/* <LocationProvider> */}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
              <BrowserRouter>
                <AuthProvider>
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                  {/* <ErrorBoundary> */}
                    <App />
                  </ErrorBoundary>
                </AuthProvider>
              </BrowserRouter>
          </LocalizationProvider>
        {/* </LocationProvider> */}
      </PersistGate>
    </ReduxProvider>
  </HelmetProvider>
);

serviceWorkerRegistration.unregister();
reportWebVitals();
