import React, { useEffect } from "react";
import { getWithExpiry, setWithExpiry } from "../utils";

export function ErrorFallback({ error }) {
  // Handles failed lazy loading of a JS/CSS chunk.
  useEffect(() => {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (error?.message && chunkFailedMessage.test(error.message)) {
      if (!getWithExpiry("chunk_failed")) {
        setWithExpiry("chunk_failed", "true", 10000);
        window.location.reload();
      }
    }
  }, [error]);

  return (
    <div>
      {/* <p>Something went wrong.</p>
      <pre>{error?.message}</pre> */}
      <h2>
        {error.name === 'ChunkLoadError' 
          ? 'This application has been updated, please refresh your browser to see the latest content.'
          : 'Something went wrong, please refresh and try again.'
        }
      </h2>
      <details style={{ whiteSpace: 'pre-wrap' }}>
        {error && error.toString()}
        <br />
        {/* {errorInfo.componentStack} */}
        {error.message}
      </details>          
    </div>
  );


    
}
