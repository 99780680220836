import { localStorage, isEmpty } from '../../utils';

export function authHeader() {
  const accessToken = localStorage.get('accessToken');
  const roleId = localStorage.get('roleId');

  if (isEmpty(accessToken)) {
    return { 'content-type': 'application/json' };
  }
  return {
    'content-type': 'application/json',
    authorization: `Bearer ${accessToken}`,
    role_id: Number(roleId)
  };
}
