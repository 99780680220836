import React, { useEffect, memo, useCallback } from 'react'; 
import PropTypes from 'prop-types';

// material
import {  TableContainer,  Table,  TableBody, Card, Pagination, Box } from '@mui/material';

// hooks 
import useTable from './useTable';

// utils 
import { isEmpty } from './utils';
// Components 
import TableHeader from './TableHeader';
import TableBodyRow from './TableBodyRow';
import NotFoundInTable from './NotFoundInTable';
import SearchBar from './SearchBar';
import Scrollbar from '../Scrollbar';
import FilterBar from './FilterBar';
import { styles } from './styles';


MyTable.propTypes = {
  HeadData: PropTypes.array,
  BodyData: PropTypes.array.isRequired,
  RowItem: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.func]),
  pagination: PropTypes.shape({
    counts: PropTypes.number,
    interval: PropTypes.number
  }),
  enableFilters: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        displayValue: PropTypes.string.isRequired,
        optionId: PropTypes.string.isRequired,
        multiSelect: PropTypes.bool,
        options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired
      })
    ),
    enableSelectAllButton: PropTypes.bool
  }),
  enableDrawer: PropTypes.shape({
    params: PropTypes.shape({
      buttonProps: PropTypes.shape({
        label: PropTypes.string,
        icon: PropTypes.any
      })
    }),
    variant:  PropTypes.oneOf(['left', 'right']),
  }),
  groupButton: PropTypes.shape({
    label: PropTypes.string,
    groupBy: PropTypes.string,
    defaultChecked: PropTypes.bool,
  }),
  callback: PropTypes.func,
  isLoading: PropTypes.bool,
  enableSearch: PropTypes.shape({
    variant: PropTypes.oneOf(['SearchStyle1', 'SearchStyle2']),
    params: PropTypes.object
  }),
  enableSort: PropTypes.bool,
  enableSelect: PropTypes.bool,
  showSelectAllButton: PropTypes.bool,
  Toolbar: PropTypes.any,
  toolbarProps: PropTypes.object,
  scrollbar: PropTypes.bool,
  controlledRow: PropTypes.bool,
  variant: PropTypes.oneOf(["normal", "margin"]),
  BulkActionBar: PropTypes.any,
  editable: PropTypes.shape({
    EditContent: PropTypes.any
  }),
  componentProps: PropTypes.object,
  showNotFound: PropTypes.bool,
}

function MyTable ({
  HeadData=[],
  BodyData=[],
  RowItem,
  pagination=null,
  enableFilters=null,
  callback,
  isLoading=false,
  enableSearch=null,
  enableSort=false,
  enableSelect=false,
  showSelectAllButton=true,
  Toolbar=null,
  toolbarProps={},
  scrollbar=true,
  controlledRow=false,
  BulkActionBar=null,
  groupButton=null,
  variant="normal",
  enableDrawer=null,
  editable=null,
  componentProps={},
  showNotFound=true
}) {
  const {  records, setRecords, order, setOrder, orderBy, setOrderBy, selected, setSelected, pageNo, setPageNo, pageCount, setPageCount,
    isRecordNotFound, setIsRecordNotFound, searchQuery, setSearchQuery, filterItemGroup, setFilterItemGroup, filterSelected, setFilterSelected, 
    group, setGroup, groupRecords, setGroupRecords, isGroupChecked, setIsGroupChecked, openDrawer, setOpenDrawer, editableContent, setEditableContent,
    editItem, setEditItem,
    handleUpdateRecords, handleUpdateFilterItems, handleSearchQuery,
    handleSortRequest, handleSelectAllClick, handleCheckboxClick, handlePagination, handleDeleteFilterItem, handleChangeFilterValues, 
    handleUpdateGroupRecords, handleSortResponse, handleToggleFilterDrawer, handleChangeEditItem
  } = useTable({});

  const tableCallback = useCallback(() => {
    if(typeof callback === 'function'){
      callback({ 
        records, setRecords, order, setOrder, orderBy, setOrderBy, selected, setSelected, pageNo, setPageNo, pageCount, setPageCount,
        isRecordNotFound, setIsRecordNotFound, searchQuery, setSearchQuery, filterItemGroup, setFilterItemGroup, filterSelected, setFilterSelected, 
        group, setGroup, groupRecords, setGroupRecords, isGroupChecked, setIsGroupChecked, openDrawer, setOpenDrawer, editableContent, setEditableContent,
        editItem, setEditItem,
        handleUpdateRecords, handleUpdateFilterItems, handleSearchQuery,
        handleSortRequest, handleSelectAllClick, handleCheckboxClick, handlePagination, handleDeleteFilterItem, handleChangeFilterValues,
        handleUpdateGroupRecords, handleSortResponse, handleToggleFilterDrawer, handleChangeEditItem
      });
    }
  },[records, selected, pageNo, groupRecords, pageCount, searchQuery, filterSelected]);

  const updateRecords = useCallback(() => {
    if(BodyData){
      if(isGroupChecked){
        handleUpdateGroupRecords(BodyData);
      } else {
        setRecords(BodyData);
      }
      setPageCount(pagination?.counts || 0);
    }
  },[BodyData, (pagination?.counts || 0)]);
  
  const updateGroup = useCallback(() => {
    if(groupButton){
      setGroup(groupButton);
      if(groupButton?.defaultChecked === true){
        setIsGroupChecked(true);
      }
    }
  },[groupButton]);

  const updateEditableContent = useCallback(() => {
    if(editable){
      setEditableContent(editable);
    }
  },[editable]);

  useEffect(()=> {handleUpdateFilterItems(enableFilters?.items);}, [enableFilters?.items]);
  useEffect(updateRecords, [updateRecords]);
  useEffect(updateGroup, [updateGroup]);
  useEffect(updateEditableContent, [updateEditableContent]);
  useEffect(tableCallback, [tableCallback]);

const TableLayout = () => (
  <TableContainer {...componentProps?.tableContainer}>
    <Table sx={styles[variant]?.table} {...componentProps?.table} >
      <TableHeader 
        order={order}
        orderBy={orderBy}
        headLabel={HeadData}
        rowCount={records.length}
        numSelected={selected.length}
        onRequestSort={handleSortRequest}
        onSelectAllClick={handleSelectAllClick}
        enableSort={enableSort}
        variant={variant}
        enableSelect={enableSelect}
        showSelectAllButton={showSelectAllButton}
        enableBulkActions={selected.length > 0}
        BulkActionBar={BulkActionBar}
        isEditable={!isEmpty(editableContent)}
      />
      {(isRecordNotFound && showNotFound) ? (<NotFoundInTable isLoading={isLoading} searchQuery={searchQuery} colSpan={enableSelect ? HeadData.length + 1 : HeadData.length} />) :
        <TableBody>
          {((isGroupChecked ? groupRecords : records) || [])?.map((row, index) => {
            const isItemSelected = isGroupChecked ? false : selected.indexOf(row.id) !== -1;
            return  controlledRow ? (
              <RowItem
                key={row?.id?.toString() || index.toString()}
                id={row?.id}
                isItemSelected={isItemSelected}
                enableSelect={enableSelect}
                handleCheckboxClick={handleCheckboxClick}
                item={row}
                index={index}
              />
            ) : (
              <TableBodyRow 
                key={index.toString()}
                variant={variant}
                item={row}
                index={index}
                selected={selected}
                isItemSelected={isItemSelected}
                handleCheckboxClick={handleCheckboxClick}
                RowItem={RowItem}
                enableSelect={enableSelect}
                isGroupChecked={isGroupChecked}
                colSpan={enableSelect ? HeadData.length + 1 : HeadData.length}
                editableContent={editableContent}
                editItem={editItem}
                handleChangeEditItem={handleChangeEditItem}
              />
            )
          })}
        </TableBody>
      }
    </Table>
  </TableContainer>
);


return (
  <Card 
    sx={{
      boxShadow: "0px 0px 2px 0px #344767",
      marginTop: "16px",
      marginBottom: "24px",
    }}
    {...componentProps?.card}
  >
    {(!isEmpty(Toolbar) && toolbarProps?.showInTop === true && toolbarProps?.withInFilterBar === undefined) && ( 
      <Toolbar 
        searchQuery={searchQuery} 
        onSearchQuery={handleSearchQuery}
        {...toolbarProps}  
      /> 
    )}
    {(enableSearch || groupButton || enableDrawer) && (
      <SearchBar 
        searchQuery={searchQuery} 
        onSearchQuery={handleSearchQuery} 
        params={enableSearch?.params || {}} 
        variant={enableSearch?.variant || 'SearchStyle1'}
        show={!isEmpty(enableSearch)}
        groupButton={groupButton}
        isGroupChecked={isGroupChecked}
        setIsGroupChecked={setIsGroupChecked}
        enableDrawer={enableDrawer}
        handleToggleFilterDrawer={handleToggleFilterDrawer}
      />
    )}

    {(enableFilters && enableFilters?.items?.length > 0) && (
      <FilterBar 
        items={enableFilters?.items} 
        selectedItems={filterSelected}
        handleDeleteFilterItem={handleDeleteFilterItem}
        handleChangeFilterValues={handleChangeFilterValues}
        Toolbar={toolbarProps?.withInFilterBar === true ? <Toolbar 
          searchQuery={searchQuery} 
          onSearchQuery={handleSearchQuery}
          {...toolbarProps}  
        /> : null}
      />
    )}
    
    {(!isEmpty(Toolbar) && toolbarProps?.showInTop !== true && toolbarProps?.withInFilterBar === undefined ) && ( 
      <Toolbar 
        searchQuery={searchQuery} 
        onSearchQuery={handleSearchQuery}
        {...toolbarProps}  
      /> 
    )}

    {scrollbar ? (
      <Scrollbar>
        <TableLayout />
      </Scrollbar>
    ) : (
      <TableLayout />
    )}

    {pagination && (
      <Pagination 
        style={{
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'flex-end',
          alignItems: 'center',
          padding: '25px',
          borderTop: '1px solid silver'
        }}
        showFirstButton
        showLastButton
        count={Math.ceil(pageCount/(pagination?.interval || 20))}
        page={pageNo}
        onChange={handlePagination}
      />
    )}
  </Card>
)};

export default memo(MyTable);