export const styles = {
  HeaderStyle1: {
    color: "#7b809a",
    alignment: 'left',
    bold: true, 
    fontSize: 9,
    margin: [10, 20, 0, 20]
  },
  BodyRowStyle1: {
    alignment: 'left',
    fontSize: 8,
    margin: [10,20,0,20]
  },
};
