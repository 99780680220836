import { memo } from 'react';
import PropTypes from 'prop-types';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';

//material
import { Box, Button, TextField, InputAdornment, Tooltip, Input, FormControlLabel, Switch } from '@mui/material';


const SearchStyle1 = memo(({ searchQuery, onSearchQuery, params }) => (
  <Box
    sx={{
      flexGrow: 1,
      m: 1.5
    }}
  >
    <TextField
      value={searchQuery}
      onChange={onSearchQuery}
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Tooltip title="Search">
              <SearchIcon fontSize="small" />
            </Tooltip>
          </InputAdornment>
        )
      }}
      {...params}
    />
  </Box>
));


const SearchStyle2 = memo(({ onSearchQuery, searchQuery, params }) => (
  <Box
    sx={{
      alignItems: 'center',
      display: 'flex',
      flexGrow: 1,
      p: 2,
      borderBottom: '1px solid #ccc'
    }}
  >
    <SearchIcon fontSize="small" />
    <Box
      sx={{
        flexGrow: 1,
        ml: 3
      }}
    >
      <Input
        disableUnderline
        fullWidth
        onChange={onSearchQuery}
        onKeyUp={onSearchQuery}
        value={searchQuery}
        {...params}
      />
    </Box>
  </Box>
));

SearchBar.propTypes = {
  searchQuery: PropTypes.string,
  onSearchQuery: PropTypes.func,
  params: PropTypes.object,
  variant: PropTypes.string,
  show: PropTypes.bool, 
  groupButton: PropTypes.object, 
  setIsGroupChecked: PropTypes.func,
  isGroupChecked: PropTypes.bool,
  enableDrawer: PropTypes.any,
  handleToggleFilterDrawer: PropTypes.func
};

function SearchBar ({ searchQuery, handleToggleFilterDrawer, onSearchQuery, params, enableDrawer, variant, show, groupButton, isGroupChecked, setIsGroupChecked }) {
  const StyledSearchBar = variant === 'SearchStyle2' ? SearchStyle2 : SearchStyle1
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        m: -1.5,
        p: 3
      }}
    >
      {show && (
        <StyledSearchBar 
          searchQuery={searchQuery} 
          onSearchQuery={onSearchQuery} 
          params={params} 
        />
      )}
      {(enableDrawer && enableDrawer?.variant === 'left') && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            ...(!show ? { mt: 3, flexGrow: 1 } : {}),
            ...(groupButton ? { mr: 3 } : {})
          }}
        >
          <Button
            endIcon={enableDrawer?.params?.buttonProps?.icon ||  <FilterAltIcon fontSize="small" />}
            onClick={handleToggleFilterDrawer}
            sx={{ m: 1 }}
            variant="outlined"
          >
            {enableDrawer?.params?.buttonProps?.label || 'Filters'}
          </Button>
        </Box>
      )}
      {groupButton && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            ...(!show ? { mt: 3, flexGrow: 1 } : {})
          }}
        >
          <FormControlLabel
            control={(
              <Switch
                checked={isGroupChecked}
                onChange={(e) => {setIsGroupChecked(e.target.checked)}}
              />
            )}
            label={groupButton?.label}
          />
        </Box>
      )}      
    </Box>
    
  )
};

export default memo(SearchBar);