import { useState, useEffect, useMemo, useCallback } from 'react';

// utils
import { arraySorter, isEmpty } from './utils';

const useTable = ({ tableRecords = [], filterItems=[], orderByProp = "id", counts = 0 }) => {
  const [records, setRecords] = useState(tableRecords);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(orderByProp);
  const [selected, setSelected] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageCount, setPageCount] = useState(counts);
  const [isRecordNotFound, setIsRecordNotFound] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterItemGroup, setFilterItemGroup] = useState(filterItems);
  const [filterSelected, setFilterSelected] = useState([]);
  const [group, setGroup] = useState(null);
  const [groupRecords, setGroupRecords] = useState([]);
  const [isGroupChecked, setIsGroupChecked] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [editableContent, setEditableContent] = useState(null);
  const [editItem, setEditItem] = useState(null);

  const handleUpdateRecords = (records) => { setRecords(records);}
  const handleUpdateFilterItems = (items) => { setFilterItemGroup(items);}
  const handleToggleFilterDrawer = () => {setOpenDrawer(!openDrawer);};
  
  const handleSearchQuery = (e) => { setSearchQuery(e.target.value); }
  
  const handleSortRequest = (property) => (event) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }

  const handleSelectAllClick = (event) => {
    if(event.target.checked){
      const newSelected = records?.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  }

  const handleCheckboxClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handlePagination = (event, page) => {
    setPageNo(page);
  };

  const handleDeleteFilterItem = (item, option) => {
    const temp = [...filterSelected];
    const length = temp?.find((ele) => ele?.id === item?.id)?.options?.length;
    if(length > 1) {
      const optionIndex = temp?.find((ele) => ele?.id === item?.id)?.options?.findIndex(e => e[item?.optionId] === option[item?.optionId]);
      temp?.find((ele) => ele?.id === item?.id)?.options?.splice(optionIndex,1);
    } else {
      const itemIndex = temp?.findIndex((ele) => ele?.id === item?.id);
      temp?.splice(itemIndex,1);
    }
    setFilterSelected([...temp]);
  };

  const handleChangeFilterValues = (e, item, option) => {
    const { id, multiSelect } = item;
    const original = filterItemGroup?.find(ele => ele?.id === id);
    const selected = filterSelected?.find(ele => ele?.id === id);
    const newValues = [...filterSelected];
 
    if (e.target.checked) {
      if(selected){
        newValues?.map(ele => {
          if(ele?.id === id) {
            ele.options = multiSelect ? [...ele?.options, option] : [option];
          }
        });
      } else {
        newValues.push({ ...original, options: [option]})
      }
      setFilterSelected(newValues);
    } else {
      handleDeleteFilterItem(original, option);
    }
  };
  
  const handleChangeEditItem = (item) => {setEditItem(item);};

  const handleSortResponse = useCallback(() => {
    if(isGroupChecked){
      if(groupRecords && groupRecords?.length > 0){
        const sortedData = groupRecords?.map(e => ({ ...e, values: arraySorter(e?.values, orderBy, order ) }));
        setGroupRecords(sortedData);
      }
    } else {
      if(records && records?.length > 0){
        setRecords(arraySorter(records, orderBy, order));
      }
    }
  }, [order, orderBy]);

  const handleUpdateGroupRecords = useCallback((records) => { 
    if(isGroupChecked){
      const keys = [...new Set(records?.map(e => String(e[group?.groupBy])))].filter(e => !isEmpty(e));
      const DEFAULT = keys?.reduce((a, v) => ({ ...a, [v]: []}), {});
      const groupedValues = (records || []).reduce((acc, data) => {
        const key = data[group?.groupBy];
        return { ...acc, [key]: [...acc[key], data] };
      }, DEFAULT);
      const groupedValuesArray = [];
      keys?.map((key, index) => {
        groupedValuesArray.push({
          id: index,
          key, 
          label: groupedValues[key][0][group?.groupLabel] || '',
          values: groupedValues[key]  
        })
      })
      setGroupRecords(groupedValuesArray);
    } else {
      setGroupRecords([]);
    }  
  }, [isGroupChecked]);
  
  useEffect(handleSortResponse, [handleSortResponse]);
  useEffect(() => {handleUpdateGroupRecords(records);}, [handleUpdateGroupRecords]);
  useEffect(() => {
      setIsRecordNotFound(records.length === 0 && groupRecords?.length === 0); 
  },[records?.length === 0, groupRecords?.length === 0]);

  return {
    records, setRecords,
    order, setOrder,
    orderBy, setOrderBy,
    selected, setSelected,
    pageNo, setPageNo,
    pageCount, setPageCount,
    isRecordNotFound, setIsRecordNotFound,
    searchQuery, setSearchQuery,
    filterItemGroup, setFilterItemGroup,
    filterSelected, setFilterSelected,
    group, setGroup,
    groupRecords, setGroupRecords,
    isGroupChecked, setIsGroupChecked,
    openDrawer, setOpenDrawer,
    editableContent, setEditableContent,
    editItem, setEditItem,
    handleUpdateRecords,
    handleUpdateFilterItems,
    handleSearchQuery,
    handleSortRequest,
    handleSelectAllClick,
    handleCheckboxClick,
    handlePagination,
    handleDeleteFilterItem,
    handleChangeFilterValues,
    handleUpdateGroupRecords,
    handleSortResponse,
    handleToggleFilterDrawer,
    handleChangeEditItem
  }
};

export default useTable;
