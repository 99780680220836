import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import pdfmake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Packer } from 'docx';

const contents = {
  xlsx: {
    contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    fileExtension: '.xlsx'
  }
};
// -------------------------------------------------------------------------------
// -------------------------------------------------------------------------------

export const PdfMakePDF = async ({ CreateDD, params, action}) => new Promise(async (resolve, reject) => {
  pdfmake.vfs = pdfFonts.pdfMake.vfs;
  const DefinedDD = await CreateDD(params);

  if(action ==='open'){
    pdfmake.createPdf(DefinedDD).open();
  } else if(action ==='download'){
    pdfmake.createPdf(DefinedDD).download(params.filename || 'file');
  } else if(action ==='print'){
    pdfmake.createPdf(DefinedDD).print();
  } else if(action === 'getBase64') {
    pdfmake.createPdf(DefinedDD).getBase64((base64String) => {
      resolve(base64String)
    });
  }
});

export const getDocx = async ({ CreateDD, params, exportAs="blob" }) => {
  const filename = `${params.filename || 'My Document'  }.docx`;
  
  try {
    const doc = await CreateDD(params);
    // console.log('fronedn',docs);
    // const options = {
    //   url: 'http://localhost:5000/api/getDocx',
    //   data: { params },
    //   method: 'POST',
    //   headers: {
    //     'content-type': 'application/json'
    //   }
    // };
    // const { data } = await axios(options);
    // console.log(data)
    // const doc = data;
    // if(exportAs ==='buffer'){
    //   Packer.toBuffer(doc).then((buffer) => {
    //     fs.writeFileSync(filename, buffer);
    //   });
    // } else 
    if(exportAs ==='base64'){
      Packer.toBase64String(doc).then((string) => {
        console.log(string);
      });
    } else if(exportAs ==='blob' || exportAs === 'buffer'){
      Packer.toBlob(doc).then((blob) => {      
        FileSaver.saveAs(blob, filename);
      });
    }
    return
  } catch(err) {
    console.error(err);
  }
};

export const getFileSize = ({ files, minSize = 0, maxSize = 0, fSExt = 'B' }) => {
  // fSExt = B/KB/MB/GB/TB
  const docs = [];
  let errorMessage = ``;
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    if (file) {
      const fSize = bytes(file.size, { unit: fSExt, unitSeparator: ' ' });
      const fRoundedSize = Number(String(fSize).split(' ')[0]);
      if (fRoundedSize > maxSize) {
        docs.push({ filename: file.name, size: fSize, minSize, maxSize, fSExt });
        errorMessage += `file ${file.name} should be less than ${maxSize} ${fSExt}. \n`;
      } else if (fRoundedSize < minSize && minSize !== 0) {
        docs.push({ filename: file.name, size: fSize, minSize, maxSize, fSExt });
        errorMessage += `file ${file.name} should be greater than ${maxSize} ${fSExt}. \n`;
      }
    }
  }
  return { files: docs, errorMessage };
};

export const bytesToSize = (bytes, decimals = 2) => {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const imagetoDataURL = (src, callback, outputFormat) => {
  try{
    let image = new Image();
    image.crossOrigin = 'Anonymous';
    image.onload = function () {
      let canvas = document.createElement('canvas');
      let ctx = canvas.getContext('2d');
      let dataURL;
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      ctx.drawImage(this, 0, 0);
      dataURL = canvas.toDataURL(outputFormat);
      callback(dataURL);
    };
    image.src = src;
    if (image.complete || image.complete === undefined) {
      image.src = "data:image/gif;base64, R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
      image.src = src;
    }
  }catch(e){
    console.log("error...", e);
  }
};

export const fileToDataURL = async (url, callback) => {
  try{
    let xhRequest = new XMLHttpRequest();
    xhRequest.onload = function () {
      let reader = new FileReader();
      reader.onloadend = function () {
       // console.log(reader);
        callback(reader.result);
      }
      reader.readAsDataURL(xhRequest.response);
    };
    xhRequest.open('GET', url);
    xhRequest.responseType = 'blob';
    xhRequest.send();
  }catch(e){
    console.log("error...", e);
  }
};

export function getBase64ImageFromURL(url) {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.setAttribute("crossOrigin", "anonymous");

    img.onload = () => {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      var dataURL = canvas.toDataURL("image/png");

      resolve(dataURL);
    };

    img.onerror = error => {
      console.error(error);
      resolve(null);
    };

    img.src = url;
  });
};

export const convertFilesToBase64 = async (files) => {
  // console.log(files);
  const documents = [];
  if (window.File && window.FileList && window.FileReader) {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file) {
        const doc = await new Promise((resolve, reject) => {
                      const reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onload = () => resolve({ data: reader.result, name: file.name });
                      reader.onerror = error => reject(error);
                    });
        documents.push(doc);
      }
    }
  } else {
    alert("Your browser doesn't support to File API");
  }
  return documents;
};

export const fileToBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve({ data: reader.result, name: file.name, pureBase64: String(reader.result).split(";base64,")[1] });
  reader.onerror = error => reject(error);
});

export const filesConvertInBase64 = async (files) => {
  // console.log(files);
  const documents = [];
  if (window.File && window.FileList && window.FileReader) {
    for (let i = 0; i < files.length; i++) {
      if (files[i]) {
        const doc = await fileToBase64(files[i]);
        documents.push(doc);
      }
    }
  } else {
    alert("Your browser doesn't support to File API");
  }
  return documents;
};

export const inputFileSelector = (props) => {
  const params = { accept: 'image/*', multiple: false, handleFileChange: () => {}, ...props };

  const input = document.createElement('input');
  input.type = 'file';
  input.accept = params.accept;
  input.multiple = params.multiple;

  input.onchange = (e) => {
    const files = Array.from(input.files);
    // console.log(files);
    params.handleFileChange(files);
  };
  input.click();
};

export const createExcelWorksheet = ({ data, options }) => {
  const ws = XLSX.utils.aoa_to_sheet(options.heading);
  XLSX.utils.sheet_add_json(ws, data, { skipHeader: true, origin: -1 });
  ws['!cols'] = options.wscols;
  ws['!rows'] = options.wsrows;
  ws['!merges'] = options.merge;
  return ws;
};

export const appendWorksheet = (worksheets) => {
  const workbook = XLSX.utils.book_new();
  
  worksheets?.map((ws, index) => {
    XLSX.utils.book_append_sheet(workbook, ws, `sheet${index+1}`);
  });
  
  return workbook;
};

export const saveWorkbook = ({ workbook, options }) => {
  const workbookout = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });
  FileSaver.saveAs(
    new Blob([workbookout], { type: contents.xlsx.contentType }),
    options.filename + contents.xlsx.fileExtension
  );
};

export const encodeWorkbook = ({ workbook, type="base64" }) => {
  const encodedWorkbook = XLSX.write(workbook, { type, bookType: 'xlsx' });
  return encodedWorkbook;
};

export const exportAsExcel = ({ data, options }) => {
  const ws = XLSX.utils.aoa_to_sheet(options.heading);
  XLSX.utils.sheet_add_json(ws, data, { skipHeader: true, origin: -1 });
  ws['!cols'] = options.wscols;
  ws['!rows'] = options.wsrows;
  ws['!merges'] = options.merge;

  const workbook = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(workbook, ws, 'sheet1');
  const workbookout = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });
  FileSaver.saveAs(
    new Blob([workbookout], { type: contents.xlsx.contentType }),
    options.filename + contents.xlsx.fileExtension
  );
};

export function getSheetHeaders(sheet) {
  // sheet  === workbook.Sheets[sheetName]
  const headerRegex = new RegExp('^([A-Za-z]+)1=\'(.*)$');

  const cells = XLSX.utils.sheet_to_formulae(sheet);
  const headerKeys = cells.filter(item => headerRegex.test(item)).map(item => item.split("='")[1]);
  const keyArray = Object.values(headerKeys)?.map((key, ind) => ( 
    { key, keyId: key.replace(/[^a-zA-Z ]/g, "").split(' ').join('_').toLowerCase() }
  ));

  return keyArray;
};

export const arrayToXLSX = (array) => {
  const worksheet = xlsx.utils.json_to_sheet(array);
  const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
  const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
  return excelBuffer;
  // return Buffer.from(excelBuffer).toString('base64');
};


