// import { useEffect } from "react";
import { ToastContainer } from 'react-toastify';

// react-router components
// import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// theme
import theme from "./assets/theme";

// components 
import { LoadingScreen, ProgressBarStyle, ScrollToTop, NotistackProvider } from "./components";
import { BaseOptionChartStyle } from "./components/MKCharts/BaseOptionChart";
// import Presentation from "./theme-pages/pages/presentation";

// hooks
import useAuth from './hooks/useAuth';

// routes 
import Router from './routes/index';

export default function App() {
  const { isInitialized } = useAuth();  

  // const getRoutes = (allRoutes) =>
  //   allRoutes.map((route) => {
  //     if (route.collapse) {
  //       return getRoutes(route.collapse);
  //     }

  //     if (route.route) {
  //       return <Route exact path={route.route} element={route.component} key={route.key} />;
  //     }

  //     return null;
  //   });

  return (
    <ThemeProvider theme={theme}>
      <NotistackProvider>
        <CssBaseline />
        <ProgressBarStyle />
        <BaseOptionChartStyle />
        <ToastContainer 
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          style={{
            fontSize: 14
          }}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover
          theme="light"
        />
        <ScrollToTop />
        {isInitialized ? <Router /> : <LoadingScreen />}
        {/* <Routes>
          {getRoutes(routes)}
          <Route path="/presentation" element={<Presentation />} />
          <Route path="*" element={<Navigate to="/presentation" />} />
        </Routes> */}
      </NotistackProvider>
    </ThemeProvider>
  );
}
