// import Config from './Config';

// const ENV = process.env.NODE_ENV ? process.env.NODE_ENV : 'development';
// const PLATFORM = process.env.PLATFORM ? process.env.PLATFORM : 'local';
// const VERSION = process.env.VERSION ? process.env.VERSION : 'development';

// const KEY = `${ENV}-${PLATFORM}-${VERSION}`;
// console.log('>>>>', KEY);

// export const { API_URL, AUTH_URL, API_CONSUMER } = Config[KEY];
// console.log('##**API_URL**##', API_URL);

const configureApiRoot = () => {
  const hostname = window && window.location && window.location.hostname;
  if(hostname === 'localhost') {
    // return 'https://dev.a1invoice.com';
    return 'http://localhost:5001';
  } else return `https://${hostname}`;
};

const URL = configureApiRoot();
export const API_URL = URL;
export const AUTH_URL = URL;
export const API_CONSUMER = URL;

console.log('##**API**##', API_CONSUMER);