import { APIService, API_CONSUMER } from '..';
const BaseURL = `${API_CONSUMER}/api/v1/user`;

export default {
  getUserData: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/getUserData`, method: 'POST' }),
  
  updateUserProfilePicture: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/updateUserProfilePicture`, method: 'POST' }),

  updateUserProfile: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/updateUserProfile`, method: 'POST' }),


  // getUsers: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/getUsers`, method: 'POST' }),

  // addUpdateUser: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/addUpdateUser`, method: 'POST' }),
  
  // changeUserStatus: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/changeUserStatus`, method: 'POST' }),

  // getUserCredentials: async ({ ...payload }) => await APIService({ payload, url: `${BaseURL}/getUserCredentials`, method: 'POST' }),
     


};
