import axios from 'axios';
import checkError from './HttpClient';
import { authHeader } from './AuthHeader';
// import { localStorage } from '../../utils';
// import { API_CONSUMER } from './URLs';

const { NODE_ENV, LOGGING, API_BASE_URL, API_KEY } = process.env;
const isLogEnabled = NODE_ENV !== 'production' && (LOGGING === true || LOGGING === 'true');

export const instance = axios.create({
  baseURL: API_BASE_URL,
  // timeout: 10000,
  headers: {
    'x-api-key': API_KEY,
    'Content-Type': 'application/json',
  },
  // maxContentLength: 5 * 1000 * 1000, // bytes => 5 MB
});

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    if (isLogEnabled) {
      console.log('Network Request:', `${config.baseURL}${config.url}`, config.method);
    }
    return config;
  },
  async (error) => {
    if (isLogEnabled) console.error('Network Request:', error);
    return Promise.reject(error);
  },
);

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  async (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (isLogEnabled) console.error('Network Response:', error);
    // throw error && error.response && error.response.data;
    
    // const originalConfig = error.config;
    // // console.log('originalConfig', originalConfig);
    // if (originalConfig?.url?.split('/').pop() !== "login" && error.response) {
    // //   // Access Token was expired
    
    //   if (error.response.status === 401 && error?.response?.data?.instruction === 'refresh_token' && !originalConfig._retry) {
    //     originalConfig._retry = true;
    //     try {
    //       const { data } = await instance.post(`${API_CONSUMER}/api/v1/auth/refreshToken`, {
    //           refreshToken: localStorage.get('refreshToken'),
    //         }, {
    //           headers: authHeader()
    //       });
    //       const { user, accessToken, refreshToken } = data;
    //       // const parsedToken = parseJwt(accessToken);
    //       localStorage.setAll([
    //         { key: 'ucrId', value: user?.role_id }, 
    //         { key: 'accessToken', value: accessToken }, 
    //         { key: 'refreshToken', value: refreshToken }, 
    //       ]);
    //       // console.log('user', data);
    //       return instance(originalConfig);
    //     } catch (_error) {
    //       return Promise.reject(_error);
    //     }
    //   }
    // }
    return Promise.reject(error);
  },
);

const APIService = async ({ payload, url, method = 'GET' }) => {
  try {
    const { CancelToken } = axios;
    const cancelRequest = payload?.axiosConfig?.cancelRequest;
    const options = {
      url,
      data: payload,
      method,
      headers: authHeader(),
    };
    const { data } = await instance.request({
      ...options, 
      cancelToken: new CancelToken((c) => {
        // An executor function receives a cancel function as a parameter
        if(typeof cancelRequest === 'function'){
          cancelRequest(c, url);
        }
      })
    });
    return data;
  } catch (error) {
    checkError(error);
  }
};

export default APIService;