import { createSlice } from '@reduxjs/toolkit';

// api
import { UserV1API } from '../../api';
// utils
import { startLoading, hasError, handleResponse, dataListState, dataState } from '../constant';

// ----------------------------------------------------------------------

const initialState = {
  // common
  isLoading: false,
  error: false,
  
  userCredentials: dataState, 
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    startLoading,
    hasError,
    handleResponse,
    // STATE CLEAN UP 
    userStateCleaner(state, action) {
      const field = action.payload;
      if(typeof field === "string"){
        state[field] = initialState[field];
      }
    },
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { userStateCleaner } = slice.actions;

// -----------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------
// export const getUsersFn = ({ 
//   field,
//   user_id=null, 
//   status=null, 
//   is_active=null,
//   role_id=null,
//   sortBy='latest', 
//   pageNo=0, 
//   searchQuery="",  
//   basic=false,
//   ...other
// }) => async (dispatch) => {
//   const payload = { user_id, status, is_active, role_id, sortBy, pageNo, searchQuery, basic, ...other };
//   try {
//     dispatch(slice.actions.startLoading(field));
//     const response = await UserV1API.getUsers({ ...payload });    
//     dispatch(slice.actions.handleResponse({ 
//       field, 
//       data: { data: isEmpty(user_id) ? response?.data?.users :  response?.data?.users[0], counts: response?.data?.counts }, 
//       response
//     }));
//     return response;
//   } catch (error) {
//     dispatch(slice.actions.hasError({ field, error }));
//   }
// };

// -----------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------
 
export const updateUserProfile = ({ ...payload }) => async (dispatch) => {
  try {
    dispatch(slice.actions.startLoading());
    return await UserV1API.updateUserProfile({ ...payload });
  } catch (error) {
    dispatch(slice.actions.hasError({ error }))
  }
};
 
export const updateUserProfilePicture = ({ ...payload }) => async (dispatch) => {
  try {
    dispatch(slice.actions.startLoading());
    return await UserV1API.updateUserProfilePicture({ ...payload });
  } catch (error) {
    dispatch(slice.actions.hasError({ error }))
  }
};

// export const getUserCredentials = ({ user_id=null, ...other }) => async (dispatch) => {  
//   const field = "userCredentials"
//   try {
//     dispatch(slice.actions.startLoading(field));
//     const response = await UserV1API.getUserCredentials({ user_id, ...other });    
//     // const { username, password } = jwt.verify(response?.data?.crentials, `${user_id}`);
//     // return { username: response?.data?.username, password: response?.data?.password };
//     dispatch(slice.actions.handleResponse({ field, data: { data: response?.data }, response }));
//     return response;
//   } catch (error) {
//     dispatch(slice.actions.hasError({ field, error }));
//   }
// };
